// ** React Imports
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'
import Script from 'next/script'

// ** MUI Imports
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** Layout Imports
// !Do not remove this Layout import
import VerticalLayout from 'src/@core/layouts/VerticalLayout'

// ** Navigation Imports
import VerticalNavItems from 'src/layouts/navigation'

// ** Component Import
import VerticalAppBarContent from './components/vertical/AppBarContent'
import { GenericLoader } from 'src/components/common/loader-components/GenericLoader'

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings'

// ** Context Imports
import { useMerchant } from 'src/lib/contexts/MerchantContext'
import { fetcher, getRequest } from 'src/lib/core/api-client'
import {
  areAllFieldsDefined,
  hasAuthCookie,
  hasRefreshCookie,
  isEmptyObj,
  setVersionCookie,
  refresh
} from 'src/lib/utils/helpers'
import { useFilter } from 'src/lib/contexts/FilterContext'
import { RouteGuard } from './navigation/RouteGuard'
import { setMerchantAttributes } from 'src/lib/core/api-methods'
import TermsAndConditionsDialog from 'src/components/common/TermsAndConditionsDialog'
import Alerts from 'src/components/common/feedback-and-dialog/Alerts'
import { alertSeverites, constants } from 'src/lib/utils/constants'
import { useLocale } from 'src/lib/hooks/useLocal'
import { refreshExpiredIdToken } from 'src/lib/utils/auth'
import Cookies from 'js-cookie'
import { UserDataType } from 'src/lib/types/user'
import { apiURI } from 'src/lib/utils/constants'
import { useUser } from 'src/lib/contexts/UserContext'
import { useLoader } from 'src/lib/contexts/LoaderContext'
import { getActiveStore } from 'src/lib/utils/onboarding'
import { useOnboarding } from 'src/lib/contexts/OnboardingContext'
import { ProductFruits } from 'react-product-fruits'
import { getAvailablePaymentModes } from 'src/lib/core/api-methods'
import clarity from 'src/lib/third-party/clarity'
import { useOrganisationContext } from 'src/lib/contexts/OrganisationContext'
import { getXShopfloVersion } from 'src/lib/core/api-methods'
import { errorToast } from 'src/lib/utils/toasters'
import Intercom from '@intercom/messenger-js-sdk'
import { useAuth } from 'src/lib/contexts/AuthContext'
import { ConsoleLine } from 'mdi-material-ui'

const PRODUCT_FRUITS_WORKSPACE_CODE: string = process.env.NEXT_PUBLIC_APP_PRODUCT_FRUITS_WORKSPACE_CODE ?? ''

interface Props {
  children: ReactNode
}

const UserLayout = ({ children }: Props) => {
  // ** Hooks
  const { t } = useLocale()
  const { settings, saveSettings } = useSettings()
  const { setMerchantData, merchant } = useMerchant()
  const router = useRouter()
  const { setFiltersCtx } = useFilter()
  const { user, setUserCtx } = useUser()
  const { showAnnouncement, hideAnnouncement } = useAuth()
  const { setLoaderProperties } = useLoader()
  const { onboarding, setActiveShop } = useOnboarding()

  const {
    getters: { organisationData, billingPopUp }
  } = useOrganisationContext()

  const [isTnCOpen, setTnCOpen] = useState<boolean>(false)
  const [openAlert, setOpenAlert] = useState<string>('')
  const [alertMessage, setAlertMessage] = useState<string>('')

  const userData = Cookies.get(constants.FLO_USER_DATE)
  let userObject: UserDataType = { email: '', name: '' }
  if (typeof userData === 'string') {
    userObject = JSON.parse(userData)
  }

  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  const isUserConfigured = useMemo(() => {
    return Boolean(user?.firstName) && Boolean(user?.phoneNumber) && Boolean(user?.role)
  }, [user])

  useEffect(() => {
    if (!merchant || !user || !organisationData || user?.isSuperAdmin || user?.isDemoUser) return
    Intercom({
      app_id: process.env.NEXT_PUBLIC_APP_INTERCOM_APP_ID ?? '',
      user_id: user.id,
      email: user.email,
      name: user.firstName + ' ' + user.lastName,
      phone: user.phoneNumber,
      // company: organisationData?.name,
      company_name: organisationData?.name,
      merchant_id: merchant.accountId,
      type: user.role,
      user_role: user.role,
      company: {
        id: merchant.accountId,
        name: organisationData?.name,
        'merchant-id': merchant.accountId,
        'shop-name': merchant.attributes?.shopName,
        website: merchant.attributes?.websiteUrl,
        industry: organisationData?.taxDetails?.category
      }
    })
  }, [merchant, user, organisationData])

  useEffect(() => {
    if (!user) return
    if (!isUserConfigured && router.asPath !== '/settings/profile') {
      router.push('/settings/profile')
      errorToast('User details are not configured')
    }
  }, [user, isUserConfigured, router])

  const checkForRefreshToken = async () => {
    if (hasRefreshCookie()) {
      await refreshExpiredIdToken()
      onInit()
    } else {
      if (router.asPath?.includes('auth')) return
      router.replace('/auth/login')
    }
  }

  useEffect(() => {
    getAvailablePaymentModes()
    if (hasAuthCookie()) {
      onInit()
      return
    }

    checkForRefreshToken()
  }, [])

  useEffect(() => {
    if (!merchant || !user) return

    if (Boolean(merchant) && Boolean(user)) {
      if ((window as any).fcWidget) {
        ;(window as any).fcWidget.user?.setProperties({
          email: user.email,
          firstName: user.firstName,
          lastName: merchant.accountId,
          cf_agent_id: organisationData?.metadata?.shopfloPoc ?? 1
        })
      }
    }

    redirectToInitialTab()

    const agreement = merchant?.attributes?.merchantAgreement
    if (
      agreement?.agreementFields &&
      areAllFieldsDefined(agreement?.agreementFields) &&
      !agreement?.hasAcceptedTerms &&
      userObject?.email !== 'Super admin'
    ) {
      setTnCOpen(true)
    }
  }, [merchant, user, billingPopUp])

  const onInit = async () => {
    const versionResponse: any = await getXShopfloVersion()
    if (versionResponse?.data?.success) {
      if (Cookies.get(constants.FLO_SHOPFLO_VERSION) !== versionResponse?.data?.data?.version) {
        setVersionCookie(versionResponse?.data?.data?.version ?? 'stable')
        refresh()
      }
    } else {
      handleAlerts(alertSeverites.SEVERITY_ERROR, versionResponse?.error)
      setVersionCookie('stable')
    }

    await initiateBaseCalls()
    setFiltersCtx()
  }

  const redirectToInitialTab = () => {
    const currPath = router.pathname
    const isAdmin = user?.isAdmin
    const isOnboardingComplete = merchant?.attributes?.isOnboardingComplete
    if (Boolean(isAdmin) && !Boolean(isOnboardingComplete)) {
      router.replace('/settings')
      return
    }
    if (billingPopUp.hasOverdueInvoices) {
      router.replace('/billing')
      return
    }

    const allNavItems = VerticalNavItems(
      merchant?.attributes?.isOnboardingComplete ?? true,
      isUserConfigured,
      billingPopUp.hasOverdueInvoices
    )
    if (!allNavItems.length || currPath === '/billing') return

    const accesibleNavItems = allNavItems?.filter((item: any) => {
      const hasPermissionToTab = user?.permissions?.some((permission: any) => permission.id === item?.rbacPermission)
      return Boolean(hasPermissionToTab)
    })

    const currPathRBACPermission = accesibleNavItems?.find((item: any) => {
      const path = item.path.split('/')[1]
      return currPath.includes(path)
    })

    const hasPermissionToTab = user?.permissions?.some(
      (permission: any) => permission.id === (currPathRBACPermission as any)?.rbacPermission
    )

    if (currPath === '/' || !Boolean(currPathRBACPermission) || !Boolean(hasPermissionToTab)) {
      const initalAccessTabPath = (accesibleNavItems[0] as any)?.path ?? '/overview'
      router.replace(initalAccessTabPath)
      return
    }
  }

  const initiateBaseCalls = async () => {
    await getAccountAttributes()
  }

  const callGetMerchantAttributes = async () => {
    try {
      const merchantData: any = await fetcher('/attributes/v1/account-attributes')
      setMerchantData(merchantData)
      clarity.setTag('email', merchantData?.email ?? '')
      clarity.setTag('merchant_id', merchantData?.account_id ?? '')
      Cookies.set(
        constants.FLO_MERCHANT_DATA,
        JSON.stringify({
          merchantID: merchantData?.account_id
        }),
        {
          expires: constants.FLO_USER_COOKIE_EXPIRY,
          sameSite: 'none',
          secure: true
        }
      )
    } catch (e) {
      console.error(e)
    }
  }

  const getAccountAttributes = async () => {
    try {
      const accountData: any = await getRequest('/merchant-user/profile', apiURI.AUTH_ENGINE)
      await callGetMerchantAttributes()
      const data = accountData?.data?.data
      setUserCtx(data)
      const activeStore = getActiveStore(data?.organizations)
      if (Boolean(activeStore?.in_onboarding)) {
        setActiveShop(activeStore?.merchant_id)
        router.push('/onboarding')
        return
      }
    } catch (e) {
      console.error(e)
    }
  }

  // ** Terms and conditions ** //

  const handleTandCAcception = async () => {
    setLoaderProperties({ isLoaderVisible: true })
    const fields = merchant?.attributes?.merchantAgreement?.agreementFields
    try {
      const modifiedAttributes = {
        attributes: {
          merchant_agreement: {
            has_accepted_terms: true,
            agreement_fields: {
              entity_name: fields?.entityName,
              poc_name: fields?.pocName,
              nature_of_entity: fields?.natureOfEntity,
              address: fields?.address,
              designation: fields?.designation,
              email: fields?.email,
              gst_number: fields?.gstNumber
            }
          }
        }
      }
      const response: any = await setMerchantAttributes(modifiedAttributes)
      if (response?.error) {
        handleAlerts(alertSeverites.SEVERITY_ERROR, response.error)
      } else {
        setMerchantData(
          {
            ...merchant,
            attributes: {
              ...merchant?.attributes,
              merchantAgreement: {
                ...merchant?.attributes?.merchantAgreement,
                hasAcceptedTerms: true
              }
            }
          },
          false
        )
        handleAlerts(alertSeverites.SEVERITY_SUCCESS, t('welcome_to_shopflo'))
        setTnCOpen(false)
      }
    } catch (error) {
      console.error(error)
      handleAlerts(alertSeverites.SEVERITY_ERROR, t('something_went_wrong'))
    }
    setLoaderProperties({ isLoaderVisible: false })
  }

  // ** Alert Handlers ** //

  const handleAlerts = (severity: string, message?: string) => {
    setOpenAlert(severity)
    message && setAlertMessage(message)
  }

  const handleCloseAlert = (_event: any, reason: any) => {
    if (reason === 'clickaway') {
      return
    }
    handleAlerts('')
  }

  const getProductFruitsUser = useCallback(() => {
    if (!user) return {}

    const userInfo = {
      username: user.email,
      email: user.email,
      firstname: user.firstName,
      role: Boolean(user.isAdmin) ? 'ADMIN' : 'MEMBER',
      props: {
        merchantId: merchant?.accountId,
        websiteUrl: merchant?.attributes?.websiteUrl,
        shopName: merchant?.attributes?.shopName
      }
    }
    return userInfo
  }, [user])

  const loggedInOrg = useMemo(() => {
    const loggedInOrg = user?.organisations?.find((org: any) => org.isLoggedIn)
    return loggedInOrg ? loggedInOrg : user?.organisations[0]
  }, [user])

  return (
    <>
      <VerticalLayout
        hidden={hidden}
        settings={settings}
        saveSettings={saveSettings}
        verticalNavItems={VerticalNavItems(
          merchant?.attributes?.isOnboardingComplete ?? true,
          isUserConfigured,
          billingPopUp.hasOverdueInvoices && !user?.isSuperAdmin
        )} // Navigation Items
        verticalAppBarContent={(
          props // AppBar Content
        ) => (
          <div className='flex flex-col w-full h-full'>
            {showAnnouncement && <>{/* use this as a template for the announcement bar */}</>}
            <div className='min-h-[3.5rem] px-[3.5rem] flex flex-row justify-between items-center'>
              <VerticalAppBarContent
                hidden={hidden}
                settings={settings}
                saveSettings={saveSettings}
                toggleNavVisibility={props.toggleNavVisibility}
                notificationContent={
                  // Boolean(announcements?.isEnabled) ? (
                  //   <AnnouncementBar message={announcements?.message ?? ''} link={announcements?.exploreLink ?? ''} />
                  // ) : (
                  //   <></>
                  // )
                  <></>
                }
              />
            </div>
          </div>
        )}
      >
        <RouteGuard>{children}</RouteGuard>
        {!isEmptyObj(getProductFruitsUser()) && !user?.isDemoUser && (
          <ProductFruits
            workspaceCode={PRODUCT_FRUITS_WORKSPACE_CODE}
            language='en'
            user={getProductFruitsUser() as any}
          />
        )}

        <TermsAndConditionsDialog
          handleClose={handleTandCAcception}
          open={isTnCOpen}
          merchantInfo={merchant?.attributes?.merchantAgreement?.agreementFields}
        />

        {/* <MigrationNotification /> */}

        {isTnCOpen ? (
          <TermsAndConditionsDialog
            handleClose={handleTandCAcception}
            open={isTnCOpen}
            merchantInfo={merchant?.attributes?.merchantAgreement?.agreementFields}
          />
        ) : (
          <></>
        )}
        {/* Alerts */}
        {openAlert.length ? (
          <Alerts
            open={true}
            duration={2000}
            closeHandler={handleCloseAlert}
            severity={openAlert}
            message={alertMessage}
          />
        ) : null}
      </VerticalLayout>
      <GenericLoader />
    </>
  )
}

export default UserLayout
