// ** React Imports
import { useState, SyntheticEvent, Fragment, useEffect } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** MUI Imports
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import { Tooltip, Typography } from '@mui/material'
import { Book, CreditCard } from 'react-feather'
import NewsWidget from 'src/@core/layouts/components/vertical/navigation/NewsWidget'

// ** Icons Imports
import LogoutVariant from 'mdi-material-ui/LogoutVariant'

// ** Contexts
import { useAuth } from 'src/lib/contexts/AuthContext'
import { useFilter } from 'src/lib/contexts/FilterContext'
import { hasAuthCookie } from 'src/lib/utils/helpers'
import { constants, permissionType } from 'src/lib/utils/constants'
import { UserDataType } from 'src/lib/types/user'
import Cookies from 'js-cookie'
import Link from '@mui/material/Link'
import { useUser } from 'src/lib/contexts/UserContext'
import BillingWidget from 'src/components/billing/BillingWidget'

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserDropdown = () => {
  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  // ** Hooks
  const { logout } = useAuth()
  const router = useRouter()
  const { canAccess } = useUser()
  const userData = Cookies.get(constants.FLO_USER_DATE)
  let userObject: UserDataType = { email: '', name: '' }
  if (typeof userData === 'string') {
    userObject = JSON.parse(userData)
  }

  // ** Handlers
  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  const handleLogOut = async () => {
    if (hasAuthCookie()) {
      logout()
    }
  }
  const hasAccessToInvoices = canAccess(permissionType.TAB_INVOICES, 0)

  return (
    <div className='flex items-center justify-end gap-2 '>
      <BillingWidget />
      <NewsWidget />
      <span className='text-xs font-normal text-gray-dark'>{userObject?.email}</span>
      <Badge
        overlap='circular'
        onClick={handleDropdownOpen}
        sx={{ cursor: 'pointer', border: '2px solid #6e6e6e', borderRadius: '50%' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Tooltip title={'Accounts'}>
          <Avatar
            alt='Merchant'
            onClick={handleDropdownOpen}
            sx={{ width: 24, height: 24, border: '2px solid #fff', borderRadius: '50%' }}
            src='/images/avatars/1.png'
          />
        </Tooltip>
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        disableAutoFocus
        onClose={() => handleDropdownClose()}
        sx={{
          '& .MuiMenu-paper': { width: 230, marginTop: 1, borderRadius: '8px', boxShadow: '0px 0px 10px 0px #0000001F' }
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', cursor: 'default' }}>
          <Typography
            variant='subtitle2'
            sx={{ color: '#4d4d4d', fontWeight: 500, display: 'flex', lineHeight: '18px' }}
          >
            Account
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary', display: 'flex', lineHeight: '16px' }}>
            {userObject?.email}
          </Typography>
        </MenuItem>

        <MenuItem
          id='navbar-menu-sign-out'
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', cursor: 'pointer' }}
          onClick={() => {
            router.push('/switch-store')
          }}
        >
          <Typography
            id='navbar-menu-sign-out'
            variant='subtitle2'
            sx={{ color: '#4d4d4d', fontWeight: 500, display: 'flex', lineHeight: '18px' }}
          >
            Change stores
          </Typography>
        </MenuItem>

        <MenuItem
          id='navbar-menu-sign-out'
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', cursor: 'pointer' }}
          onClick={handleLogOut}
        >
          <Typography
            id='navbar-menu-sign-out'
            variant='subtitle2'
            sx={{ color: '#4d4d4d', fontWeight: 500, display: 'flex', lineHeight: '18px' }}
          >
            Sign out
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default UserDropdown
