import { OrganisationData, OverDueStatus } from '../contexts/OrganisationContext'
import { capitalizeFirstCharacter, formatDate, numberWithCommas } from './helpers'

export const parseOrganisationData = (data: any): OrganisationData => {
  return {
    name: data?.name ?? '',
    metadata: {
      shopfloPoc: data?.metadata?.shopflo_poc ?? 0
    },
    taxDetails: {
      category: data?.tax_details?.category ?? '',
      pocDetails: getPocDetails(data?.tax_details?.poc_details) ?? []
    },
    billingPopUp: {
      visible: true,
      dismissible: data?.billing_popup?.dismissable ?? false,
      status: getStatus(data?.billing_popup?.status) ?? 'none',
      sentInvoiceMonth: capitalizeFirstCharacter(data?.billing_popup?.sent_invoice_month) ?? '',
      sentInvoiceDueDate: formatDate(data?.billing_popup?.sent_invoice_due_date) ?? '',
      numberOfOverDueInvoices: data?.billing_popup?.number_of_overdue_invoices ?? 0,
      overDueAmount: numberWithCommas(data?.billing_popup?.overdue_amount ?? 0, 2, true) ?? '0',
      overDueSince: data?.billing_popup?.overdue_since ?? 0,
      hasOverdueInvoices: data?.billing_popup?.has_overdue_invoices ?? false
    },
    createdAt: data?.created_at ?? '',
    isOverDueInvoicesBannerOpen: true,
    payNowClicked: false
  }
}

export const getPocDetails = (pocDetails: any) => {
  if(!Boolean(pocDetails) || pocDetails.length === 0 ) return [] 
  return pocDetails.map((poc: any) => ({
    pocName: poc.name,
    pocEmail: poc.email,
    pocPhone: poc.phone,
    isPrimaryPoc: poc.primary
  }))
}

export const getStatus = (status: string): OverDueStatus => {
  switch (status) {
    case 'INVOICES_GENERATED':
      return 'first-reminder'
    case 'REMINDER_STATE':
      return 'second-reminder'
    case 'OVERDUE':
      return 'overdue'
    default:
      return 'none'
  }
}

export const getDueStatusText = (
  dueStatus: string,
  dueDate: string,
  isDueDatePassed: boolean,
  header: boolean = false
) => {
  if (dueStatus === 'today') return header ? 'due today' : 'is due today'
  else if (dueStatus === 'tomorrow') return header ? 'due tomorrow' : 'is due tomorrow'
  else if (isDueDatePassed) return header ? `` : `has been due since ${dueDate}`
  else return header ? `is due in ${getNumberOfDaysCount(dueDate)} days` : `due on ${dueDate}`
}

export const getNumberOfDaysCount = (dueDateStr: string) => {
  const today = new Date()
  const dueDate = new Date(dueDateStr)
  return Math.round((dueDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24))
}

interface DueDateStatus {
  isDueDatePassed: boolean
  dueStatus: string
}

export const getDueDateStatus = (sentInvoiceDueDate: string): DueDateStatus => {
  const dueDate = new Date(sentInvoiceDueDate)
  const today = new Date()

  const isSameDate =
    dueDate.getFullYear() === today.getFullYear() &&
    dueDate.getMonth() === today.getMonth() &&
    dueDate.getDate() === today.getDate()

  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  const isTomorrow =
    dueDate.getFullYear() === tomorrow.getFullYear() &&
    dueDate.getMonth() === tomorrow.getMonth() &&
    dueDate.getDate() === tomorrow.getDate()

  let status = ''
  if (isSameDate) status = 'today'
  else if (isTomorrow) status = 'tomorrow'

  return {
    isDueDatePassed: dueDate.getTime() < today.getTime(),
    dueStatus: status
  }
}
