import { Tooltip } from '@mui/material'
import RipplePulse from '../common/loader-components/RipplePulse'
import { CreditCard } from 'react-feather'
import { useRouter } from 'next/router'
import { useOrganisationContext } from 'src/lib/contexts/OrganisationContext'
import { useLocale } from 'src/lib/hooks/useLocal'
import { useMemo } from 'react'
import { getDueDateStatus, getDueStatusText } from 'src/lib/utils/OrganisationHelpers'

const BillingWidget = () => {
  const router = useRouter()
  const {
    getters: { billingPopUp, status }
  } = useOrganisationContext()
  const { t } = useLocale()

  const { isDueDatePassed, dueStatus } = useMemo(
    () => getDueDateStatus(billingPopUp.sentInvoiceDueDate),
    [billingPopUp.sentInvoiceDueDate]
  )

  const dueDateText = getDueStatusText(dueStatus, billingPopUp.sentInvoiceDueDate, isDueDatePassed, true)

  return (
    <Tooltip title='View Invoices'>
      <div
        className='flex gap-x-3 justify-center cursor-pointer items-center bg-white rounded-lg border border-coal-dark p-2 mr-2'
        onClick={() => router.push('/billing')}
      >
        {['second-reminder', 'first-reminder', 'overdue'].includes(status) ? <RipplePulse color='bg-red-dark' /> : <></>}
        <div className='flex items-center gap-x-1'>
          <CreditCard color='black' size={16} />
          <span className='text-coal-dark font-semibold text-sm mr-1'>{t('billing.title')}</span>
          {status === 'second-reminder' && <span className='text-gray-dark font-medium text-sm '>{dueDateText}</span>}
          {status === 'overdue' && (
            <span className='text-red-dark font-semibold text-sm ml-1'>{t('billing.overdue')}</span>
          )}
        </div>
      </div>
    </Tooltip>
  )
}

export default BillingWidget
