export enum FileTypeOptions {
  CSV = 'CSV',
  PDF = 'PDF',
  PNG = 'PNG',
  JPG = 'JPG',
  JPEG = 'JPEG',
}

// These types will be used when all file related flows move to the new file upload flow
export type UploadedFileType = 
  | {
      type: FileTypeOptions.CSV
      name: string
      size: number
      count: number
      url: string
    }
  | {
      type: FileTypeOptions.PDF
      name: string
      size: number
      url: string
    }
  | {
      type: FileTypeOptions.PNG | FileTypeOptions.JPG | FileTypeOptions.JPEG
      name: string
      size: number
      url: string
    }
