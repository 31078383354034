import { getRequest } from '../core/api-client'
import { AppStoreAppType, appStoreBackendType } from '../types/AppStoreTypes'
import { apiURI } from './constants'

export const getShopfloAppsParsedData = (data: appStoreBackendType[]) => {
  if (!data || !data?.length) return []
  const allApps = data?.map((app: appStoreBackendType) => ({
    id: app.id,
    status: app.active,
    name: app.name,
    displayName: app.display_name,
    description: app.description,
    documentation: app.documentations,
    images: app.images,
    features: app.features,
    category: app.category,
    pricingConfig: app.pricing_config,
    trialPeriodconfig: app.trial_period_config,
    subscriptionCounts: app.subscriptions_count,
    installationActions: app.installation_actions,
    defaultPricingconfig: app.default_pricing_config,
    merchantAppDetails: app.merchant_app_details,
    uninstallActions: app.uninstall_actions,
    type: app.type,
    reviews: app.reviews,
    markedupPrice: app.marked_up_price,
    metadata: app.metadata,
    media: app.medias,
    rating: app.rating,
    numberOfBrandUsing: app.number_of_brands_using,
    brandDetails: app.brand_details,
    redirectUrl: app.redirect_url,
    calendlyLink: app.calendly_link,
    linkedTabName: app.linked_tab_name,
    externalSupportRequired: app.external_support_required,
    externalDescription: app.external_description,
    pricingTiers: app.pricing_tiers
  }))

  return allApps
}

export const getFeaturedAppDetails = (data: any): AppStoreAppType => {
  const appDetails: AppStoreAppType = {
    id: data?.data?.id,
    status: data?.data?.active,
    name: data?.data?.name,
    displayName: data?.data?.display_name,
    description: data?.data?.description,
    documentation: data?.data?.documentations,
    images: data?.data?.images,
    features: data?.data?.features,
    category: data?.data?.category,
    pricingConfig: data?.data?.pricing_config,
    trialPeriodConfig: data?.data?.trial_period_config,
    subscriptionCounts: data?.data?.subscriptions_count,
    installationActions: data?.data?.installation_actions,
    defaultPricingConfig: data?.data?.default_pricing_config,
    merchantAppDetails: data?.data?.merchant_app_details,
    uninstallActions: data?.data?.uninstall_actions,
    type: data?.data?.type,
    reviews: data?.data?.reviews,
    markedupPrice: data?.data?.marked_up_price,
    metadata: data?.data?.metadata,
    media: data?.data?.medias?.sort((a: any, b: any) => a.priority - b.priority),
    rating: data?.data?.rating,
    numberOfBrandUsing: data?.data?.number_of_brands_using,
    brandDetails: data?.data?.brand_details,
    redirectUrl: data?.data?.redirect_url,
    calendlyLink: data?.data?.calendly_link,
    linkedTabName: data?.data?.linked_tab_name,
    externalSupportRequired: data?.data?.external_support_required,
    externalDescription: data?.data?.external_description,
    pricingTiers: data?.data?.pricing_tiers
  }

  return appDetails
}

export const refetchShopfloApps = async () => {
  try {
    const resp: any = await getRequest('/shopflo-apps', apiURI.SETTLEMENT_ENGINE)
    if (!resp.error) {
      const { data } = resp
      const parsedData = getShopfloAppsParsedData(data?.data)
      return parsedData
    }
  } catch (error) {
    console.error('Oops something went wrong: ', error)
  }
}
