// ** React Imports
import { useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'

// ** Theme Config Import
import themeConfig from 'src/@core/configs/themeConfig'

// ** Type Import
import { LayoutProps, NavLink } from 'src/@core/layouts/types'

// ** Components
import AppBar from './components/vertical/appBar'
import Navigation from './components/vertical/navigation'
import Footer from './components/shared-components/footer'
import { useUser } from 'src/lib/contexts/UserContext'
import { useAuth } from 'src/lib/contexts/AuthContext'
import { useOrganisationContext } from 'src/lib/contexts/OrganisationContext'
import { classNames } from 'src/lib/utils/helpers'
import { SketchLogo } from '@phosphor-icons/react'
import { PlayCircle } from 'react-feather'
import { useRouter } from 'next/router'
import VerticalNavItems from 'src/layouts/navigation'
import FloGenericDialog from 'src/components/common/feedback-and-dialog/GenericDialog'
import { useLocale } from 'src/lib/hooks/useLocal'

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex'
})

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100vh',
  marginTop: '4rem',
  flexDirection: 'column'
})

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0,
    paddingRight: 0
  }
}))

const VerticalLayout = (props: LayoutProps) => {
  const { user } = useUser()
  const { showAnnouncement } = useAuth()
  const {
    getters: { status, isOverDueInvoicesBannerOpen, billingPopUp, organisationData }
  } = useOrganisationContext()
  // ** Props
  const { settings, children, scrollToTop } = props

  // ** Vars
  const { contentWidth } = settings
  const navWidth = themeConfig.navigationSize

  // ** States
  const [navVisible, setNavVisible] = useState<boolean>(false)

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible)

  const isDemoUser = user?.isDemoUser

  return (
    <>
      {isDemoUser && (
        <div className='demo-banner fixed bottom-0 w-full bg-[#D1D1E9] text-black text-sm font-semibold text-center p-2 shadow-md z-20'>
          This is a Demo Dashboard. Use the password:
          <strong className='text-[#01A68D] text-base'> shopflo </strong> to access the store. Visit the
          <a
            href='https://demo-shopflo.myshopify.com/'
            target='_blank'
            rel='noopener noreferrer'
            className='text-[#01A68D] font-bold hover:text-[#018C7A] hover:underline ml-1 mr-1'
          >
            Demo Shopflo Store
          </a>
          to experience our checkout.
        </div>
      )}

      <VerticalLayoutWrapper className='layout-wrapper'>
        {/* Navigation Menu */}
        <AppBar toggleNavVisibility={toggleNavVisibility} {...props} />
        <Navigation
          navWidth={navWidth}
          navVisible={navVisible}
          setNavVisible={setNavVisible}
          toggleNavVisibility={toggleNavVisibility}
          className={`${showAnnouncement ? '!pt-[4rem]' : ''}`}
          {...props}
        />
        <MainContentWrapper
          className={classNames(
            'layout-content-wrapper',
            showAnnouncement ? 'pt-16' : '',
            status === 'overdue' && isOverDueInvoicesBannerOpen && billingPopUp.visible ? 'xl:pt-[120px] pt-32' : '',
            status === 'second-reminder' && billingPopUp.visible ? 'pt-4' : '',
            status === 'first-reminder' && isOverDueInvoicesBannerOpen && billingPopUp.visible ? 'pt-4' : ''
          )}
        >
          {/* AppBar Component */}

          {/* Content */}
          <ContentWrapper
            className='layout-page-content'
            sx={{
              ...(contentWidth === 'boxed' && {
                mx: 'auto',
                '@media (min-width:1440px)': { maxWidth: 1440 },
                '@media (min-width:1200px)': { maxWidth: '100%' }
              })
            }}
          >
            <TrialBanner />
            {children}
          </ContentWrapper>

          {/* Footer Component */}
          <Footer {...props} />
        </MainContentWrapper>
      </VerticalLayoutWrapper>
    </>
  )
}

export default VerticalLayout

export const TrialBanner = () => {
  const { t } = useLocale()
  const router = useRouter()
  const currPath = router.pathname
  const allNavItems = VerticalNavItems(true, true, true)
  const {
    getters: { organisationData }
  } = useOrganisationContext()

  const [showBannerVideo, setShowBannerVideo] = useState(false)

  const currentTabObj = allNavItems?.find((item: any) => currPath.includes(item?.path?.split('/')[1]))
  const tabParentPermissionName = (currentTabObj as NavLink)?.rbacPermission

  const activeApp = organisationData.shopfloApps?.find(
    app =>
      app?.merchantAppDetails?.active &&
      app?.linkedTabName === tabParentPermissionName &&
      app?.merchantAppDetails?.trial_period_status !== 'COMPLETED' &&
      app?.merchantAppDetails?.num_days_left_on_trial
  )

  if (!activeApp) return <></>

  const daysLeft = activeApp?.merchantAppDetails?.num_days_left_on_trial
  const activeAppVideo = activeApp?.media?.find(item => item.type === 'VIDEO' && item.tags?.[0] === 'HOW_TO_VIDEO')
  const daysLeftText = daysLeft === 1 ? `${daysLeft} day` : `${daysLeft} days`

  return (
    <div className='flex justify-between pb-4 px-5 border-b-3 items-center -mx-6 mb-4'>
      <div className='flex gap-2 items-center border-1 rounded-2xl py-1 px-3 text-carbon'>
        <SketchLogo size={16} />
        <span>Trial period active</span>
        <span className='p-[3px] bg-gray-dark rounded-full'></span>
        <span>{daysLeftText}</span>
      </div>
      {activeAppVideo && (
        <div className='flex gap-2 items-center cursor-pointer' onClick={() => setShowBannerVideo(true)}>
          <PlayCircle size={16} />
          <p>{t('how_to_use_this_feature')}</p>
        </div>
      )}
      {activeAppVideo && showBannerVideo && (
            <div
              className='fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-[999999]'
              onClick={() => setShowBannerVideo(false)}
            >
              <div className='rounded-lg max-w-3xl max-h-[80%] overflow-hidden'>
                <iframe
                  width={activeAppVideo?.width ?? '680'}
                  height={activeAppVideo?.height ?? '400'}
                  src={activeAppVideo?.url}
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                  className='rounded-lg'
                ></iframe>
              </div>
        </div>
      )}
    </div>
  )
}
