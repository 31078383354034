import { useUser } from '../contexts/UserContext'
import { permissionType } from '../utils/constants'

export const useAppStorePermissions = () => {
  const { canAccess } = useUser()

  const hasAppStoreTabAccess = canAccess(permissionType.TAB_APP_STORE, 0)
  const canViewAppStore = canAccess(permissionType.APP_STORE_VIEW, 0)
  const canInstallApp = canAccess(permissionType.APP_STORE_INSTALL, 0)
  const canUninstallApp = canAccess(permissionType.APP_STORE_UNINSTALL, 0)

  return {
    hasAppStoreTabAccess,
    canViewAppStore,
    canInstallApp,
    canUninstallApp
  }
}
