// ** MUI Imports

// ** Icons Imports
import { Menu as MenuIcon } from 'react-feather'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

// ** Components
// import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import { Link } from '@mui/material'
import { X } from 'react-feather'
import React from 'react'
import StoreSwitcher from 'src/layouts/StoreSwitcher'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
  notificationContent: JSX.Element
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, toggleNavVisibility, notificationContent } = props

  return (
    <div className='w-full flex items-center justify-between '>
      <div className='actions-left flex items-center'>
        {hidden ? (
          <button onClick={toggleNavVisibility} className='mr-3.5 text-white'>
            <MenuIcon fill={'#fff'} />
          </button>
        ) : (
          <div className='flex items-center justify-center pt-2 gap-4'>
            <Link href='/overview'>
              <img height='24' src='/images/shopflo_logo.svg' alt='shopflo-logo' className='min-w-[100px]' />
            </Link>
            <X className='text-[#949494]' size={14} />
            <StoreSwitcher isDark={false} />
          </div>
        )}
      </div>
      <div className='actions-right flex items-center'>
        {/* <ModeToggler settings={settings} saveSettings={saveSettings} /> */}
        <UserDropdown />
      </div>
    </div>
  )
}

export default AppBarContent
