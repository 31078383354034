// ** React Import
import { ReactNode, useState } from 'react'

// ** MUI Import
import List from '@mui/material/List'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'
import { VerticalNavItemsType } from 'src/@core/layouts/types'

// ** Component Imports
import Drawer from './Drawer'
import VerticalNavItems from './VerticalNavItems'
import VerticalNavHeader from './VerticalNavHeader'
import { classNames } from 'src/lib/utils/helpers'
import { SquaresFour } from '@phosphor-icons/react'
import { ChevronRight } from 'react-feather'
import { useRouter } from 'next/router'
import { useAppStorePermissions } from 'src/lib/access-permission-hooks/useAppStorePermissions'
import { useLocale } from 'src/lib/hooks/useLocal'

interface Props {
  hidden: boolean
  navWidth: number
  settings: Settings
  children: ReactNode
  navVisible: boolean
  toggleNavVisibility: () => void
  setNavVisible: (value: boolean) => void
  verticalNavItems?: VerticalNavItemsType
  saveSettings: (values: Settings) => void
  verticalNavMenuContent?: (props?: any) => ReactNode
  afterVerticalNavMenuContent?: (props?: any) => ReactNode
  beforeVerticalNavMenuContent?: (props?: any) => ReactNode
  className?: string
}

const Navigation = (props: Props) => {
  // ** Props
  const { hidden, verticalNavMenuContent: userVerticalNavMenuContent } = props

  // ** States
  const router = useRouter()
  const { t } = useLocale()
  const { canViewAppStore, hasAppStoreTabAccess } = useAppStorePermissions()
  const [groupActive, setGroupActive] = useState<string[]>([])
  const [currentActiveGroup, setCurrentActiveGroup] = useState<string[]>([])

  return (
    <div
      className={classNames(
        'sticky top-0 left-0 h-screen bg-gray-lightest',
        hidden ? 'border-0' : 'border-r-2 border-gray-light',
        hidden ? '' : 'min-w-[260px]',
        'custom-scrollbar'
      )}
      style={{ height: `100vh` }}
    >
      <Drawer {...props}>
        <VerticalNavHeader {...props} />
        <div className='h-[calc(100vh-100px)] lg:h-[calc(100vh-56px)] flex flex-col'>
          <div className={classNames(hidden ? '' : 'min-w-[260px]', 'custom-scrollbar flex-1 overflow-y-scroll overflow-x-hidden')}>
            <div className='flex flex-1 flex-col justify-between bg-gray-lightest '>
              {userVerticalNavMenuContent ? (
                userVerticalNavMenuContent(props)
              ) : (
                <List className='nav-items' sx={{ transition: 'padding .25s ease', padding: '0.5rem' }}>
                  <VerticalNavItems
                    groupActive={groupActive}
                    setGroupActive={setGroupActive}
                    currentActiveGroup={currentActiveGroup}
                    setCurrentActiveGroup={setCurrentActiveGroup}
                    {...props}
                  />
                </List>
              )}
            </div>
          </div>
          {canViewAppStore && hasAppStoreTabAccess && (
            <div
              className='text-base font-semibold py-2 px-[11px] border-t-1 border-r-1 bg-white cursor-pointer'
              onClick={() => router.push('/app-store')}
            >
              <div className='flex gap-2 items-center py-4 px-3 w-[236px] rounded-2xl border-2'>
                <span className='p-2 bg-gray-light rounded-full'>
                  <SquaresFour size={16} weight='fill' />
                </span>
                <div className='flex flex-col gap-[2px]'>
                  <span className='flex items-center gap-[6px]'>
                    <p className='text-sm font-semibold text-nowrap'>{t('app_store')}</p>
                    <span className='text-[11px] py-[2px] px-[6px] bg-gradient-to-r from-primary-dark to-green-dark rounded-2xl text-white'>
                      {t('new')}
                    </span>
                    <ChevronRight size={20} />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </div>
  )
}

export default Navigation
