const RipplePulse = ({ color = 'bg-red-dark' }: { color: string }) => {
  return (
    <span className='relative flex size-3 items-center justify-center'>
      <span className={`absolute inline-flex h-full w-full animate-ping rounded-full ${color} opacity-75`}></span>
      <span className={`relative inline-flex size-2 items-center justify-center rounded-full ${color}`}></span>
    </span>
  )
}

export default RipplePulse
