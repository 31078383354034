import { useMemo, useState } from 'react'
import { X } from 'react-feather'
import AlertTriangle from 'public/images/billing/alert-triangle.svg'
import { useRouter } from 'next/router'
import RipplePulse from 'src/components/common/loader-components/RipplePulse'
import { useOrganisationContext } from 'src/lib/contexts/OrganisationContext'
import { useUser } from 'src/lib/contexts/UserContext'
import { useLocale } from 'src/lib/hooks/useLocal'
import { getDueStatusText, getDueDateStatus } from 'src/lib/utils/OrganisationHelpers'

const OverDueInvoicesBanner = () => {
  const {
    getters: { status }
  } = useOrganisationContext()

  switch (status) {
    case 'first-reminder':
      return <FirstReminderBanner />
    case 'second-reminder':
      return <SecondReminderBanner />
    case 'overdue':
      return <FinalNoticeBanner />
    default:
      return <></>
  }
}

export default OverDueInvoicesBanner

const FirstReminderBanner = () => {
  const {
    getters: { billingPopUp, isOverDueInvoicesBannerOpen }
  } = useOrganisationContext()
  const { user } = useUser()
  const { t } = useLocale()

  return isOverDueInvoicesBannerOpen && billingPopUp.visible ? (
    <div className='bg-green-light w-full flex items-center justify-between p-[10px] text-sm'>
      <div className='flex items-center w-full justify-center'>
        <p className='text-white text-center'>
          {t('billing.first_reminder_invoice_message', {
            firstName: user?.firstName,
            lastName: user?.lastName,
            sentInvoiceMonth: billingPopUp.sentInvoiceMonth,
            sentInvoiceDueDate: billingPopUp.sentInvoiceDueDate
          })}
        </p>
        <OverDueInvoicesBannerTermsAndConditions />
      </div>
      <OverDueInvoicesBannerDismissButton />
    </div>
  ) : (
    <></>
  )
}

const SecondReminderBanner = () => {
  const {
    getters: { isOverDueInvoicesBannerOpen, billingPopUp }
  } = useOrganisationContext()
  const { t } = useLocale()

  const { isDueDatePassed, dueStatus } = useMemo(
    () => getDueDateStatus(billingPopUp.sentInvoiceDueDate),
    [billingPopUp.sentInvoiceDueDate]
  )

  return billingPopUp.visible && isOverDueInvoicesBannerOpen ? (
    <div className='bg-lavender-dark w-full flex items-center justify-between p-[10px] text-sm'>
      <p className='text-white flex-grow text-center'>
        <strong>{t('billing.action_required')}</strong>{' '}
        {t('billing.invoice_due_message', {
          amount: billingPopUp.overDueAmount,
          dueStatusText: getDueStatusText(dueStatus, billingPopUp.sentInvoiceDueDate, isDueDatePassed)
        })}
        <OverDueInvoicesBannerTermsAndConditions />
      </p>
      <OverDueInvoicesBannerDismissButton />
    </div>
  ) : (
    <></>
  )
}

const FinalNoticeBanner = () => {
  const router = useRouter()
  const {
    getters: { isOverDueInvoicesBannerOpen, billingPopUp },
    actions: { setPayNowClicked }
  } = useOrganisationContext()
  
  const { t } = useLocale()

  return billingPopUp.visible && isOverDueInvoicesBannerOpen ? (
    <div className='w-full flex flex-col items-center justify-between p-5 pb-3 rounded-b-2xl text-sm bg-carbon gap-3 border-t-2 border-coal-light'>
      <div className='flex items-center gap-2 justify-between w-full bg-[#FAFAFA1A] p-3 rounded-xl'>
        <div className='flex items-center gap-8 '>
          <div className='flex flex-col gap-2 text-sm'>
            <p className='text-carbon text-medium text-white'>{t('billing.overdue_amount')}</p>
            <p className='text-white font-semibold '>₹{billingPopUp.overDueAmount}</p>
          </div>
          <div className='flex items-center gap-2 border border-red-dark rounded-2xl px-3 py-2 bg-white text-red-dark'>
            <RipplePulse color='bg-red-dark' />
            <p>
              {t('billing.invoices_overdue_by', {
                numberOfOverDueInvoices: billingPopUp.numberOfOverDueInvoices,
                days: billingPopUp.overDueSince
              })}
            </p>
          </div>
        </div>
        <div>
          <button
            className='bg-white text-coal-dark px-3 py-2 rounded-lg'
            onClick={() => {
              router.push('/billing')
              setPayNowClicked(true)
            }}
          >
            {t('billing.pay_now')}
          </button>
        </div>
      </div>
      <div className='flex items-center justify-between w-full'>
        <div className='flex items-center gap-2 justify-center w-[90%]'>
          <AlertTriangle />
          <p className='text-white text-base font-medium'>{t('billing.dashboard_access_restricted')}</p>
        </div>
        <div className='flex items-center gap-2'>
          <OverDueInvoicesBannerTermsAndConditions />
          <OverDueInvoicesBannerDismissButton />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

const OverDueInvoicesBannerTermsAndConditions = () => {
  const { t } = useLocale()
  return (
    <a
      className='text-white text-sm ml-4 border-b-1 border-white cursor-pointer'
      href='https://intercom.help/shopflo-a9de00772be8/en/articles/10665779-shopflo-payment-terms-updated'
      target='_blank'
      rel='noreferrer'
    >
      {t('billing.view_t_c')}
    </a>
  )
}

const OverDueInvoicesBannerDismissButton = () => {
  const {
    getters: { billingPopUp },
    actions: { setOverDueInvoicesBannerOpen }
  } = useOrganisationContext()
  const { t } = useLocale()
  return (
    <div className='flex items-center gap-6'>
      {billingPopUp.dismissible && (
        <button className='text-white' onClick={() => setOverDueInvoicesBannerOpen(false)}>
          <X size={16} />
        </button>
      )}
    </div>
  )
}
