// ** React Imports
import { Icon } from 'react-feather'
import { ReactNode } from 'react'

import { NavLink } from 'src/@core/layouts/types'

interface UserIconProps {
  iconProps?: NavLink['iconProp']
  icon: string[] | ReactNode | Icon
}

const UserIcon = (props: UserIconProps) => {
  // ** Props
  const { icon, iconProps } = props

  const IconTag = icon

  let styles

  // @ts-ignore
  return <IconTag {...iconProps} style={{ ...styles, strokeWidth: '2.5px' }} />
}

export default UserIcon
