import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { fetcher, staticOptions } from 'src/lib/core/api-client'
import useSWR from 'swr'
import { apiURI } from 'src/lib/utils/constants'
import { useAuth } from './AuthContext'
import { parseOrganisationData } from '../utils/OrganisationHelpers'
import { getShopfloAppsParsedData } from '../utils/AppStoreHelper'
import { AppStoreAppType } from '../types/AppStoreTypes'

enum OrganisationActionType {
  SET_ORGANISATION_DATA = 'SET_ORGANISATION_DATA',
  SET_OVER_DUE_INVOICES_BANNER_OPEN = 'SET_OVER_DUE_INVOICES_BANNER_OPEN',
  SET_PAY_NOW_CLICKED = 'SET_PAY_NOW_CLICKED',
  SET_SHOPFLO_APPS = 'SET_SHOPFLO_APPS'
}

type OrganisationAction =
  | { type: OrganisationActionType.SET_ORGANISATION_DATA; payload: any }
  | { type: OrganisationActionType.SET_OVER_DUE_INVOICES_BANNER_OPEN; payload: boolean }
  | { type: OrganisationActionType.SET_PAY_NOW_CLICKED; payload: boolean }
  | { type: OrganisationActionType.SET_SHOPFLO_APPS; payload: any }
export type OverDueStatus = 'overdue' | 'first-reminder' | 'second-reminder' | 'none'

export interface OrganisationData {
  name: string
  metadata: {
    shopfloPoc: number
  }
  taxDetails: {
    category: string
    pocDetails: {
      pocName: string
      pocEmail: string
      pocPhone: string
      isPrimaryPoc: boolean
    }[]
  }
  billingPopUp: {
    visible: boolean
    dismissible: boolean
    status: OverDueStatus
    sentInvoiceMonth: string
    sentInvoiceDueDate: string
    numberOfOverDueInvoices: number
    overDueAmount: string
    overDueSince: number
    hasOverdueInvoices: boolean
  }
  isOverDueInvoicesBannerOpen: boolean
  createdAt: string
  payNowClicked: boolean
  shopfloApps?: AppStoreAppType[]
}

const initialOrganisationData: OrganisationData = {
  name: '',
  metadata: {
    shopfloPoc: 0
  },
  taxDetails: {
    category: '',
    pocDetails: []
  },
  billingPopUp: {
    visible: false,
    dismissible: false,
    status: 'none',
    sentInvoiceMonth: '',
    sentInvoiceDueDate: '',
    numberOfOverDueInvoices: 0,
    overDueAmount: '0',
    overDueSince: 0,
    hasOverdueInvoices: false
  },
  isOverDueInvoicesBannerOpen: true,
  createdAt: '',
  payNowClicked: false,
  shopfloApps: []
}

interface OrganisationTypeContext {
  state: OrganisationData
  actions: {
    setOrganisationData: (data: any) => void
    setOverDueInvoicesBannerOpen: (value: boolean) => void
    setPayNowClicked: (value: boolean) => void
    setShopfloApps: (data: any) => void
  }
  getters: {
    organisationData: OrganisationData
    billingPopUp: OrganisationData['billingPopUp']
    status: OverDueStatus
    hasOverdueInvoices: boolean
    isOverDueInvoicesBannerOpen: boolean
    pocDetails: OrganisationData['taxDetails']['pocDetails']
    createdAt: string
    payNowClicked: boolean
  }
}

function organisationReducer(state: OrganisationData, action: OrganisationAction): OrganisationData {
  switch (action.type) {
    case OrganisationActionType.SET_ORGANISATION_DATA:
      return { ...state, ...action.payload }
    case OrganisationActionType.SET_OVER_DUE_INVOICES_BANNER_OPEN:
      return { ...state, isOverDueInvoicesBannerOpen: action.payload }
    case OrganisationActionType.SET_PAY_NOW_CLICKED:
      return { ...state, payNowClicked: action.payload }
    case OrganisationActionType.SET_SHOPFLO_APPS:
      return { ...state, shopfloApps: action.payload }
    default:
      return state
  }
}

const OrganisationContext = createContext<OrganisationTypeContext | undefined>(undefined)

const OrganisationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { loggedIn } = useAuth()
  const { data: orgResponse, isValidating } = useSWR(
    loggedIn ? [`/organization`, apiURI.AUTH_ENGINE] : null,
    ([url, baseURI]) => fetcher(url, baseURI),
    staticOptions
  )

  const { data: shopfloAppsData, isLoading } = useSWR(
    loggedIn ? [`/shopflo-apps`, apiURI.SETTLEMENT_ENGINE] : null,
    ([url, baseURI]) => fetcher(url, baseURI),
    staticOptions
  )

  const [state, dispatch] = useReducer(organisationReducer, initialOrganisationData)

  useEffect(() => {
    if (isValidating || !orgResponse) return

    const { data } = orgResponse as any
    if (!data) return
    const parsedData = parseOrganisationData(data)
    setOrganisationData(parsedData)
  }, [isValidating, orgResponse])

  useEffect(() => {
    if (isLoading || !shopfloAppsData) return

    const { data } = shopfloAppsData as any
    const parsedShopfloApps = getShopfloAppsParsedData(data)
    setShopfloApps(parsedShopfloApps)
  }, [shopfloAppsData, isLoading])

  const setShopfloApps = (data: any) => {
    dispatch({ type: OrganisationActionType.SET_SHOPFLO_APPS, payload: data })
  }

  const setOrganisationData = (data: any) => {
    dispatch({ type: OrganisationActionType.SET_ORGANISATION_DATA, payload: data })
  }

  const setOverDueInvoicesBannerOpen = (value: boolean) => {
    dispatch({ type: OrganisationActionType.SET_OVER_DUE_INVOICES_BANNER_OPEN, payload: value })
  }

  const setPayNowClicked = (value: boolean) => {
    dispatch({ type: OrganisationActionType.SET_PAY_NOW_CLICKED, payload: value })
  }

  const actions = {
    setOrganisationData,
    setOverDueInvoicesBannerOpen,
    setPayNowClicked,
    setShopfloApps
  }

  const getters = {
    organisationData: state,
    billingPopUp: state.billingPopUp,
    status: state.billingPopUp.status,
    hasOverdueInvoices: state.billingPopUp.hasOverdueInvoices,
    isOverDueInvoicesBannerOpen: state.isOverDueInvoicesBannerOpen,
    pocDetails: state.taxDetails.pocDetails,
    createdAt: state.createdAt,
    payNowClicked: state.payNowClicked,
    shopfloApps: state.shopfloApps
  }

  return <OrganisationContext.Provider value={{ state, actions, getters }}>{children}</OrganisationContext.Provider>
}

export const useOrganisationContext = () => {
  const context = useContext(OrganisationContext)
  if (!context) {
    throw new Error('useOrganisation must be used within an OrganisationProvider')
  }
  return context
}

export default OrganisationProvider
