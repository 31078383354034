import { GoogleFontFaceOptionsBackend, GoogleFontFaceOptions, SSOMarketingPopup } from '@shopflo/ui'
import { SSOConfigForBackend } from '../types/SSOSetupTypes'

export const defaultSSOConfig = {
  logoUrl: '',
  backgroundColor: '#FFFFFF',
  popTriggerTime: 10000,
  borderRadius: '8px',
  primaryCTA: {
    text: 'Continue',
    color: '#FFFFFF',
    backgroundColor: '#000000'
  },
  title: { text: 'Add a title here', color: '#E0E0E0' },
  subTitle: { text: 'Add a description  ', color: '#E0E0E0' },
  banner: {
    enabled: true,
    backgroundColor: '#F3F3F3',
    textColor: '#949494',
    items: [
      {
        enabled: true,
        type: 'text',
        textColor: '#949494',
        backgroundColor: '#F3F3F3',
        htmlTextString: ''
      }
    ]
  },
  card: {
    enabled: true,
    backgroundColor: '#FFFFFF',
    textColor: '#E0E0E0',
    borderColor: '#000000',
    borderWidth: '1px',
    borderRadius: '8px',
    items: [
      {
        title: 'Card header',
        description: 'Description'
      }
    ]
  },
  postLogin: {
    backgroundColor: '#FFFFFF',
    primaryCTA: {
      text: 'Continue',
      color: '#FFFFFF',
      backgroundColor: '#000000'
    },
    title: { text: 'Add a title here', color: '#000000' },
    subtitle: { text: 'Add a description  ', color: '#000000' },
    discountCardEnabled: false
  }
}

export const defaultSSOCard = {
  title: 'Card header ',
  description: 'Add a description here'
}

export const createSSOUIPayload = (data: SSOMarketingPopup): SSOConfigForBackend => {
  return {
    popup: {
      should_load_font_faces: data.shouldLoadFontFaces,
      font_type: data.fontType,
      custom_font_faces: data.customFontFaces ?? [],
      logo_url: data.logoUrl,
      background_color: data.backgroundColor,
      pop_trigger_time: data.popTriggerTime,
      border_radius: data.borderRadius,
      google_font_faces: createGoogleFontFacesPayload(data.googleFontFaces ?? []),
      primary_cta: {
        text: data.primaryCTA.text,
        color: data.primaryCTA.color,
        background_color: data.primaryCTA.backgroundColor
      },
      title: {
        text: data.title.text,
        color: data.title.color
      },
      sub_title: {
        text: data.subTitle?.text ?? '',
        color: data.subTitle?.color ?? ''
      },
      banner: {
        enabled: data.banner.enabled,
        background_color: data.banner.backgroundColor,
        text_color: data.banner.textColor ?? '',
        items: data.banner.items.map(item => ({
          type: item.type,
          enabled: item.enabled,
          text_color: item.textColor ?? '',
          html_text_string: item.htmlTextString,
          background_color: item.backgroundColor
        }))
      },
      card: {
        enabled: data.card.enabled,
        background_color: data.card.backgroundColor,
        text_color: data.card.textColor,
        border_color: data.card.borderColor,
        border_width: data.card.borderWidth,
        border_radius: data.card.borderRadius,
        items: data.card.items.map(item => ({
          is_top_icon_text_or_image: item.isTopIconTextOrImage,
          top_icon_text: item.topIconText,
          top_icon_text_color: item.topIconTextColor,
          top_icon_image: item.topIconImage,
          title: item.title,
          title_color: item.titleColor,
          description: item.description,
          description_color: item.descriptionColor,
          border_color: item.borderColor,
          border_width: item.borderWidth,
          border_radius: item.borderRadius,
          background_color: item.backgroundColor,
          background_image: item.backgroundImage
        }))
      },
      // TODO(shradhan): Add these types on the backend @poojitha
      ...(data.postLogin && {
        post_login: {
          background_color: data.postLogin.backgroundColor,
          primary_cta: {
            text: data.postLogin.primaryCTA.text,
            color: data.postLogin.primaryCTA.color,
            background_color: data.postLogin.primaryCTA.backgroundColor
          },
          title: {
            text: data.postLogin.title.text,
            color: data.postLogin.title.color
          },
          sub_title: {
            text: data.postLogin.subtitle.text,
            color: data.postLogin.subtitle.color
          },
          discount_card_enabled: data.postLogin.discountCardEnabled
        }
      })
    }
  }
}

export const createGoogleFontFacesPayload = (data: GoogleFontFaceOptions[]): GoogleFontFaceOptionsBackend[] => {
  if (!data) return []
  return data.map(item => ({
    family_name: item.familyName ?? '',
    unicode_range: item.unicodeRange ?? '',
    src: item.src ?? '',
    style: item.style ?? '',
    weight: item.weight ?? '',
    display: item.display ?? ''
  }))
}

export const getBannerBackgroundColor = (banner: any, index: number): string => {
  const itemColor = banner.items[index]?.backgroundColor
  const bannerColor = banner.backgroundColor
  return itemColor || bannerColor || '#000000'
}

export const getBannerTextColor = (banner: any, index: number): string => {
  const itemColor = banner.items[index]?.textColor
  const bannerColor = banner.textColor
  return itemColor || bannerColor || '#FFFFFF'
}
