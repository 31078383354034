import { regexPatterns } from './validations'

export const currencyFormatter = (
  price: number | string = 0,
  showCurrencySymbol = true,
  options = { locale: 'en-IN', currency: 'INR' }
) => {
  const formatter = new Intl.NumberFormat(options.locale, {
    style: 'currency',
    currency: options.currency
  })
  const formattedPrice = formatter.format(+price)

  const finalPrice = Number.isInteger(Number(price))
    ? formattedPrice.slice(0, formattedPrice.length - 3)
    : formattedPrice

  return showCurrencySymbol ? finalPrice : finalPrice.slice(1)
}

/**
 * formats a string input to a valid zip code
 *
 * @param  {string} value       The value to be formatted
 * @param  {object} values      The collection of values to check the country code from
 * @return {string}             Returns the formatted value
 */
export const formatZip = (value: string, values: any) => {
  if (values?.country_code !== 'IN') {
    return value.replace(regexPatterns.alphaNumeric, '')
  }
  if (regexPatterns.numeric.test(value)) {
    return value.replace(regexPatterns.numeric, '')
  }
  return value
}

// file is always in bytes here
export const formatFileSize = (size: number) => {
  if (size === 0) return '0 B'
  if (size < 1024) return `${size} B`
  const kb = (size / 1024).toFixed(2)
  if (size < 1024 * 1024) return `${kb} KB`
  const mb = (size / (1024 * 1024)).toFixed(2)
  if (size < 1024 * 1024 * 1024) return `${mb} MB`
  const gb = (size / (1024 * 1024 * 1024)).toFixed(2)
  return `${gb} GB`
}
