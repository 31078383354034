// ** MUI Imports
import { Theme } from '@mui/material/styles'

// ** Theme Config Imports
import themeConfig from 'src/@core/configs/themeConfig'

const Button = (theme: Theme) => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: 8,
          // lineHeight: 1.4,
          fontSize: 14,
          // letterSpacing: '0.3px',
          textTransform: 'none',
          whiteSpace: 'nowrap',
          padding: `${theme.spacing(1.875, 3.25)}`,
          color: '#4d4d4d'
        },
        contained: {
          boxShadow: 'none',
          outline: '2px #01CCA7 solid',
          color: '#ffffff',
          padding: `${theme.spacing(1.875, 3.25)}`,
          ':hover': {
            background: '#01B896',
            color: 'text.header',
            outline: '2px #01CCA7 solid',
            boxShadow: 'none'
          },
          '&.Mui-disabled': {
            outline: 'none',
          }
        },
        outlined: {
          padding: `${theme.spacing(1.875, 3.25)}`,
          borderColor: `#f0f0f0`,
          color: '#4d4d4d',
          ':hover': {
            background: '#fAfAfA',
            color: 'text.header',
            boxShadow: '0px 0px 0px 1px #E0E0E0 inset',
            border: '1px solid #E0E0E0'
          },
          background: '#fff'
        },
        sizeSmall: {
          padding: `${theme.spacing(1, 2.25)}`,
          '&.MuiButton-contained': {
            padding: `${theme.spacing(1, 2.5)}`
          },
          '&.MuiButton-outlined': {
            padding: `${theme.spacing(0.75, 2.25)}`
          }
        },
        sizeLarge: {
          padding: `${theme.spacing(2.125, 5.5)}`,
          '&.MuiButton-contained': {
            padding: `${theme.spacing(2.125, 6.5)}`
          },
          '&.MuiButton-outlined': {
            padding: `${theme.spacing(1.875, 6.25)}`
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: themeConfig.disableRipple
      }
    }
  }
}

export default Button
