// @ts-nocheck
import {
  DiscountFormUIConfigType,
  CatalogueCategoryType,
  BulkGenerationMethodType,
  DiscountFormStateType,
  DiscountFlowConfigType,
  JourneyLineStepConfigType,
  DiscountCreationModeConfigType,
  DiscountFormErrorState,
  checklistItemsType,
  FeatherIconType,
  RadioGroupConfigType
} from 'src/lib/types/discounts'
import { ExportOptionConfigType } from 'src/lib/types/exports'
import { permissionType } from 'src/lib/utils/constants'
import { SwitchableTabType } from 'src/lib/types/random'
/*****************************************************************************************/
//                                     For UI control
/*****************************************************************************************/
export const paymentModeMappings = [
  {
    label: 'UPI',
    valueType: 'UPI'
  },
  {
    label: 'Wallets',
    valueType: 'WALLET'
  },
  {
    label: 'Debit/Credit cards',
    valueType: 'CARD'
  },
  {
    label: 'EMI',
    valueType: 'EMI'
  },
  {
    label: 'Pay later',
    valueType: 'PAY_LATER'
  },
  {
    label: 'Netbanking',
    valueType: 'NETBANKING'
  },
  {
    label: 'Snapmint',
    valueType: 'SNPM'
  },
  {
    label: 'BharatX',
    valueType: 'BHARATX'
  },
  {
    label: 'PhonePe',
    valueType: 'PL'
  }
]
const applicationMethodRadioGroupOptions = [
  {
    label: 'Discount code',
    valueType: 'MANUAL'
  },
  {
    label: 'Automatic',
    valueType: 'AUTOMATIC'
  }
]
const paymentOffersApplicationMethodRadioGroupOptions = [
  // {
  //     label: "Discount code application limits payment options (Manual)",
  //     valueType: "MANUAL"
  // },
  {
    label: 'When a payment method is selected (Automatic)',
    valueType: 'AUTOMATIC'
  }
]
const identifierInputConfigs = [
  {
    label: 'Discount code',
    valueType: 'MANUAL'
  },
  {
    label: 'Discount title',
    valueType: 'AUTOMATIC'
  }
]
const applicationRadioGroupOptions = [
  {
    label: 'Give a discount on cart items',
    valueType: 'AMOUNT'
  },
  {
    label: 'Add discounted items to the cart',
    valueType: 'BXGY',
    permissionId: permissionType.ITEM_OFFER_DISCOUNTS
  },
  {
    label: 'Create a bundle',
    valueType: 'BUNDLE',
    permissionId: permissionType.BUNDLE_DISCOUNTS
  }
]
const entitlementAmountRadioGroupOptions = [
  {
    label: 'On all cart items',
    valueType: 'ALL'
  },
  {
    label: 'On specific products',
    valueType: 'PRODUCT'
  },
  {
    label: 'On products from specific collections',
    valueType: 'COLLECTION'
  }
]
const catalogueSelectionConfigs = [
  {
    isHidden: true,
    valueType: 'ALL'
  },
  {
    addButton: {
      text: 'Add product',
      iconComponent: 'PlusCircle' as FeatherIconType,
      type: 'outlined' as const
    },
    catalogueType: 'PRODUCT_AND_VARIANT' as CatalogueCategoryType,
    searchLabel: 'Search product',
    searchTitle: 'Add products',
    valueType: 'PRODUCT'
  },
  {
    addButton: {
      text: 'Add collection',
      iconComponent: 'PlusCircle' as FeatherIconType,
      type: 'outlined' as const
    },
    catalogueType: 'COLLECTION' as CatalogueCategoryType,
    searchLabel: 'Search collection',
    searchTitle: 'Add collections',
    valueType: 'COLLECTION'
  }
]

const amountOfferValueOptions = [
  {
    label: 'Percentage off',
    valueType: 'PERCENTAGE'
  },
  {
    label: 'Fixed amount',
    valueType: 'FLAT'
  }
]
const paymentOfferValueOptions = [
  {
    label: 'Percentage off',
    valueType: 'PERCENTAGE'
  },
  {
    label: 'Percentage off upto (Capped discount)',
    valueType: 'PERCENTAGE_CAPPED'
  },
  {
    label: 'Fixed amount',
    valueType: 'FLAT'
  },
  {
    label: 'Add a free item (Freebie)',
    valueType: 'FREEBIE'
  }
]
const paymentModeCoverageOptions = [
  {
    label: 'All online payment modes',
    valueType: 'ALL'
  },
  {
    label: 'Set for individual payment modes',
    valueType: 'SPECIFIC'
  }
]
const paymentModeCheckboxConfigs = [
  {
    label: 'Discount code',
    valueType: true
  },
  {
    isHidden: true,
    valueType: false
  }
]
const paymentChecklistOptions: checklistItemsType[] = paymentModeMappings?.map((mode: any) => {
  return {
    valueType: mode?.valueType,
    paymentModeCheckbox: [
      {
        isHidden: true,
        valueType: false
      },
      {
        label: mode?.label,
        valueType: true
      }
    ],
    paymentModeOfferInput: [
      {
        valueType: 'PERCENTAGE_CAPPED',
        endIconComponent: '%',
        type: 'number' as any
      },
      {
        valueType: 'PERCENTAGE',
        endIconComponent: '%',
        type: 'number' as any
      },
      {
        valueType: 'FLAT',
        startIconComponent: '₹',
        type: 'number' as any
      }
    ],
    paymentModeCapInput: [
      {
        isHidden: true,
        valueType: 'PERCENTAGE'
      },
      {
        valueType: 'PERCENTAGE_CAPPED',
        startIconComponent: '₹',
        type: 'number' as any
      },
      {
        isHidden: true,
        valueType: 'FLAT'
      }
    ]
  }
})
const amountOfferValueInputConfigs = [
  {
    label: 'Percentage off',
    valueType: 'PERCENTAGE',
    endIconComponent: '%',
    type: 'number' as any
  },
  {
    label: 'Discount value',
    valueType: 'FLAT',
    startIconComponent: '₹',
    type: 'number' as any
  }
]
const paymentModeOfferValueInputConfigs = [
  {
    label: 'Percentage off',
    valueType: 'PERCENTAGE',
    endIconComponent: '%',
    type: 'number' as any
  },
  {
    label: 'Percentage off',
    valueType: 'PERCENTAGE_CAPPED',
    endIconComponent: '%',
    type: 'number' as any
  },
  {
    label: 'Discount value',
    valueType: 'FLAT',
    startIconComponent: '₹',
    type: 'number' as any
  }
]
const capValueInputConfigs = [
  {
    isHidden: true,
    valueType: false
  },
  {
    label: 'Percentage off upto',
    valueType: true,
    startIconComponent: '₹',
    type: 'number' as any
  }
]
const capCheckboxConfigs = [
  {
    label: 'Set discount cap (Percentage off upto)',
    valueType: 'PERCENTAGE'
  },
  {
    isHidden: true,
    valueType: 'FLAT'
  }
]

const codCheckboxConfigs = [
  {
    isHidden: true,
    valueType: 'PREPAID'
  },
  {
    isHidden: true,
    valueType: 'BXGY'
  },
  {
    isHidden: true,
    valueType: 'BUNDLE'
  },
  {
    isHidden: true,
    valueType: 'AMOUNT'
  }
]
const generationRadioGroupOptions = [
  {
    valueType: 'RANDOM_CODES',
    label: 'Generate random codes'
  },
  {
    valueType: 'CSV_UPLOAD',
    label: 'Upload a CSV'
  }
]
const allowedCharactersRadioGroupOptions = [
  {
    label: 'Letter and numbers',
    valueType: 'LETTERS_AND_NUMBERS'
  },
  {
    label: 'Only letters',
    valueType: 'LETTERS_ONLY'
  },
  {
    label: 'Only numbers',
    valueType: 'NUMBERS_ONLY'
  }
]
const thresholdRadioGroupOptions = [
  {
    label: 'No conditions',
    valueType: 'NONE'
  },
  {
    label: 'Purchase amount of items in the cart',
    valueType: 'AMOUNT'
  },
  {
    label: 'Number of items in the cart',
    valueType: 'QUANTITY'
  }
]
const prerequisiteRadioGroupOptions = [
  {
    label: 'For the entire cart',
    valueType: 'ALL'
  },
  {
    label: 'For specific products in cart',
    valueType: 'PRODUCT'
  },
  {
    label: 'For products in specific collections in cart',
    valueType: 'COLLECTION'
  }
]
const eligibilityRadioGroupOptions = [
  {
    valueType: 'ALL',
    label: 'All customers'
  },
  {
    valueType: 'FIRST',
    label: 'First-time customers'
  },
  {
    valueType: 'CONDITIONAL',
    label: 'Lifetime order count'
  },
  {
    valueType: 'SPECIFIC',
    label: 'Specific customers'
  },
  {
    valueType: 'SEGMENTS',
    label: 'Specific customer segments'
  }
]
const entitlementBundleRadioGroupOptions = [
  {
    valueType: 'ALL',
    label: 'All cart items'
  },
  {
    valueType: 'PRODUCT',
    label: 'Specific products'
  },
  {
    valueType: 'COLLECTION',
    label: 'Products from specific collections'
  }
]

const entitlementBXGYRadioGroupOptions = [
  {
    valueType: 'PRODUCT',
    label: 'Specific products and their variants'
  },
  {
    valueType: 'COLLECTION',
    label: 'Products from specific collections'
  }
]

const customStackabilityRadioGroupOptions = [
  {
    valueType: 'NONE',
    label: 'Don’t combine with other discounts'
  },
  {
    valueType: 'ALL',
    label: 'Combine with all active discounts'
  },
  {
    valueType: 'CUSTOM',
    label: 'Customise combination'
  }
]

export const stackabilityRadioGroupOptions = [
  {
    valueType: 'NONE',
    label: 'Don’t combine with other discounts'
  },
  {
    valueType: 'ALL',
    label: 'Combine with all active discounts'
  }
]

const uploadCSVUIConfigs = [
  {
    isHidden: true,
    valueType: 'ALL'
  },
  {
    isHidden: true,
    valueType: 'FIRST'
  },
  {
    isHidden: true,
    valueType: 'CONDITIONAL'
  },
  {
    valueType: 'SPECIFIC',
    uploadButton: {
      text: 'Upload list of phone numbers',
      iconComponent: 'Upload' as FeatherIconType,
      type: 'outlined' as any
    },
    infoText: 'Please ensure the uploaded file is in the right format.',
    sampleDownloadLink: {
      text: 'Download a sample file',
      type: 'link' as any,
      link: 'https://dkuy6716075w5.cloudfront.net/template_numbers.csv'
    }
  }
]
const minOrderCountInputConfigs = [
  {
    isHidden: true,
    valueType: 'ALL'
  },
  {
    isHidden: true,
    valueType: 'FIRST'
  },
  {
    label: 'Minimum order count',
    valueType: 'CONDITIONAL',
    type: 'number' as any
  },
  {
    isHidden: true,
    valueType: 'SPECIFIC'
  }
]
const maxOrderCountInputConfigs = [
  {
    isHidden: true,
    valueType: false
  },
  {
    label: 'Maximum order count',
    valueType: true,
    type: 'number' as any
  }
]
const maxOrderCountCheckboxConfigs = [
  {
    isHidden: true,
    valueType: 'ALL'
  },
  {
    isHidden: true,
    valueType: 'FIRST'
  },
  {
    label: 'Set maximum order count',
    valueType: 'CONDITIONAL'
  },
  {
    isHidden: true,
    valueType: 'SPECIFIC'
  }
]
export const discountFormUIConfig: DiscountFormUIConfigType = {
  setupUIConfig: {
    titleSectionUIConfig: [
      {
        isHidden: true,
        valueType: 'AMOUNT'
      },
      {
        isHidden: true,
        valueType: 'BXGY'
      },
      {
        isHidden: true,
        valueType: 'BUNDLE'
      },
      {
        isHidden: true,
        valueType: 'PREPAID'
      },
      {
        isHidden: false,
        valueType: 'CALLOUT_CARD',
        title: 'Callout title',
        titleInput: {
          fieldTitle: 'Callout title',
          characterLimit: 25,
          placeholder: 'Enter title of callout card'
        }
      }
    ],
    descriptionSectionUIConfig: [
      {
        isHidden: true,
        valueType: 'AMOUNT'
      },
      {
        isHidden: true,
        valueType: 'BXGY'
      },
      {
        isHidden: true,
        valueType: 'BUNDLE'
      },
      {
        isHidden: true,
        valueType: 'PREPAID'
      },
      {
        isHidden: false,
        valueType: 'CALLOUT_CARD',
        title: 'Description',
        headerInput: {
          fieldTitle: 'Subtitle',
          characterLimit: 50,
          placeholder: 'Enter heading of callout card'
        },
        descriptionInput: {
          fieldTitle: 'Body text',
          characterLimit: 100,
          placeholder: 'Enter description of callout card'
        },
        longDescriptionInput: {
          fieldTitle: 'Details',
          characterLimit: 300,
          placeholder: 'Enter details of callout card'
        }
      }
    ],
    applicationMethodUIConfig: [
      {
        valueType: 'NOT_APPLICABLE',
        isHidden: true
      },
      {
        valueType: 'POST_ORDER_PLACEMENT',
        isHidden: true
      },
      {
        valueType: 'PRE_PAYMENT_VALIDATION',
        methodAndIdentifierUIConfig: [
          {
            valueType: 'SINGLE',
            title: 'Method of application',
            applicationMethodRadioGroup: { radioItemsUIConfig: paymentOffersApplicationMethodRadioGroupOptions },
            codeInput: identifierInputConfigs
          }
        ]
      },
      {
        valueType: 'CART_DISCOVERY',
        methodAndIdentifierUIConfig: [
          {
            valueType: 'BULK',
            title: 'Title',
            applicationMethodRadioGroup: { isHidden: true },
            codeInput: [
              {
                label: 'Discount set title',
                valueType: 'MANUAL'
              }
            ]
          },
          {
            valueType: 'SINGLE',
            title: 'Method of application',
            applicationMethodRadioGroup: { radioItemsUIConfig: applicationMethodRadioGroupOptions },
            codeInput: identifierInputConfigs
          }
        ]
      }
    ],
    applicationUIConfig: [
      {
        isHidden: true,
        valueType: 'NOT_APPLICABLE'
      },
      {
        isHidden: true,
        valueType: 'POST_ORDER_PLACEMENT'
      },
      {
        isHidden: true,
        valueType: 'PRE_PAYMENT_VALIDATION'
      },
      {
        valueType: 'CART_DISCOVERY',
        title: 'What should this discount do?',
        applicationRadioGroup: { radioItemsUIConfig: applicationRadioGroupOptions },
        maxUsesPerOrderCheckbox: [
          {
            label: 'Set a maximum number of uses per order',
            isHidden: true,
            valueType: 'BULK'
          },
          {
            label: 'Set a maximum number of uses per order',
            valueType: 'SINGLE'
          }
        ],
        maxUsesPerOrderInput: [
          {
            isHidden: true,
            valueType: false
          },
          {
            valueType: true,
            type: 'number' as any
          }
        ]
      }
    ],
    offerValueUIConfig: [
      {
        isHidden: true,
        valueType: 'CALLOUT_CARD'
      },
      {
        title: 'Set discount value',
        valueType: 'PREPAID',
        offerValueRadioGroup: { radioItemsUIConfig: paymentOfferValueOptions },
        codCheckbox: codCheckboxConfigs,
        paymentModeCoverageRadioGroup: { radioItemsUIConfig: paymentModeCoverageOptions },
        paymentCheckList: [
          {
            isHidden: true,
            valueType: 'ALL'
          },
          {
            checklistItems: paymentChecklistOptions,
            valueType: 'SPECIFIC'
          }
        ],
        offerValueInput: paymentModeOfferValueInputConfigs,
        capCheckbox: [
          {
            isHidden: true,
            valueType: 'PERCENTAGE'
          },
          {
            isHidden: true,
            valueType: 'FLAT'
          }
        ],
        capValueInput: [
          {
            isHidden: true,
            valueType: 'PERCENTAGE'
          },
          {
            label: 'Percentage off upto',
            valueType: 'PERCENTAGE_CAPPED',
            startIconComponent: '₹',
            type: 'number' as any
          },
          {
            isHidden: true,
            valueType: 'FLAT'
          },
          {
            isHidden: true,
            valueType: 'FREEBIE'
          }
        ],
        offerQuantityInput: { isHidden: true }
      },
      {
        title: 'Set discount type',
        valueType: 'AMOUNT',
        offerValueRadioGroup: { radioItemsUIConfig: amountOfferValueOptions },
        paymentModeCoverageRadioGroup: { isHidden: true },
        paymentCheckList: [
          {
            isHidden: true,
            valueType: 'ALL'
          }
        ],
        codCheckbox: codCheckboxConfigs,
        offerValueInput: amountOfferValueInputConfigs,
        capCheckbox: capCheckboxConfigs,
        capValueInput: capValueInputConfigs,
        offerQuantityInput: { isHidden: true }
      },
      {
        title: 'Products to be added',
        valueType: 'BXGY',
        offerValueRadioGroup: { isHidden: true },
        offerValueInput: [amountOfferValueInputConfigs[0]],
        capCheckbox: [
          {
            isHidden: true,
            valueType: 'PERCENTAGE'
          }
        ],
        paymentModeCoverageRadioGroup: { isHidden: true },
        paymentCheckList: [
          {
            isHidden: true,
            valueType: 'ALL'
          }
        ],
        codCheckbox: codCheckboxConfigs,
        capValueInput: [capValueInputConfigs[0]],
        offerQuantityInput: {
          label: 'Quantity of products',
          type: 'number'
        }
      },
      {
        title: 'Build your bundle',
        valueType: 'BUNDLE',
        offerValueRadioGroup: { isHidden: true },
        offerValueInput: [
          {
            label: 'Price of the bundle',
            valueType: 'FLAT',
            startIconComponent: '₹',
            type: 'number'
          }
        ],
        paymentModeCoverageRadioGroup: { isHidden: true },
        paymentCheckList: [
          {
            isHidden: true,
            valueType: 'ALL'
          }
        ],
        codCheckbox: codCheckboxConfigs,
        capCheckbox: [
          {
            isHidden: true,
            valueType: 'FLAT'
          }
        ],
        capValueInput: [capValueInputConfigs[0]],
        offerQuantityInput: {
          label: 'Size of the bundle',
          type: 'number'
        }
      }
    ],
    entitlementUIConfig: [
      {
        isHidden: true,
        valueType: 'CALLOUT_CARD'
      },
      {
        isHidden: true,
        valueType: 'PREPAID',
        entitledItemsUIConfig: catalogueSelectionConfigs
      },
      {
        valueType: 'AMOUNT',
        title: 'What should this discount be applied on?',
        entitlementRadioGroup: { radioItemsUIConfig: entitlementAmountRadioGroupOptions },
        entitledItemsUIConfig: catalogueSelectionConfigs
      },
      {
        valueType: 'BXGY',
        title: 'How do you want to select your items?',
        entitlementRadioGroup: { radioItemsUIConfig: entitlementBXGYRadioGroupOptions },
        entitledItemsUIConfig: [
          {
            addButton: {
              text: 'Add product',
              iconComponent: 'PlusCircle',
              type: 'outlined'
            },
            catalogueType: 'PRODUCT_AND_VARIANT',
            searchLabel: 'Search product',
            searchTitle: 'Add products',
            valueType: 'PRODUCT'
          },
          {
            addButton: {
              text: 'Add collection',
              iconComponent: 'PlusCircle',
              type: 'outlined'
            },
            catalogueType: 'COLLECTION',
            searchLabel: 'Search collection',
            searchTitle: 'Add collections',
            valueType: 'COLLECTION'
          }
        ]
      },
      {
        valueType: 'BUNDLE',
        title: 'Bundle contents',
        entitlementRadioGroup: { radioItemsUIConfig: entitlementBundleRadioGroupOptions },
        entitledItemsUIConfig: [
          {
            isHidden: true,
            valueType: 'ALL'
          },
          {
            addButton: {
              text: 'Add product',
              iconComponent: 'PlusCircle',
              type: 'outlined'
            },
            catalogueType: 'PRODUCT_AND_VARIANT',
            searchLabel: 'Search product',
            searchTitle: 'Add products',
            valueType: 'PRODUCT'
          },
          {
            addButton: {
              text: 'Add collection',
              iconComponent: 'PlusCircle',
              type: 'outlined'
            },
            catalogueType: 'COLLECTION',
            searchLabel: 'Search collection',
            searchTitle: 'Add collections',
            valueType: 'COLLECTION'
          }
        ]
      }
    ],
    discountGenerationUIConfig: [
      {
        isHidden: true,
        valueType: 'SINGLE'
      },
      {
        title: 'How do you want to create the discount codes for this set?',
        valueType: 'BULK',
        generationRadioGroup: { radioItemsUIConfig: generationRadioGroupOptions },
        bulkGeneration: [
          {
            valueType: 'RANDOM_CODES' as BulkGenerationMethodType,
            codeCountInput: {
              label: 'Number of codes to generate',
              type: 'number'
            },
            codeLengthInput: {
              label: 'Code length',
              type: 'number'
            },
            prefixInput: {
              label: 'Prefix'
            },
            suffixInput: {
              label: 'Suffix'
            },
            allowedCharactersTitle: 'Allowed characters',
            allowedCharactersRadioGroup: { radioItemsUIConfig: allowedCharactersRadioGroupOptions }
          },
          {
            valueType: 'CSV_UPLOAD' as BulkGenerationMethodType,
            uploadCSVUIConfig: {
              uploadButton: {
                text: 'Upload codes',
                iconComponent: 'Upload',
                type: 'outlined' as any
              },
              infoText: 'Please ensure the uploaded file is in the right format.',
              sampleDownloadLink: {
                text: 'Download a sample file',
                type: 'link' as any,
                link: 'https://dkuy6716075w5.cloudfront.net/template.csv'
              }
            }
          }
        ]
      }
    ]
  },
  conditionsUIConfig: {
    selectedEntitledItemsUIConfig: [
      {
        valueType: 'CALLOUT_CARD',
        isHidden: true
      },
      {
        valueType: 'PREPAID',
        isHidden: true
      },
      {
        valueType: 'AMOUNT',
        isHidden: true
      },
      {
        valueType: 'BXGY',
        title: 'Items on which discounts will be applied',
        tooltip: 'These items will need to be present in the cart for the discount to be applied'
      },
      {
        valueType: 'BUNDLE',
        title: 'Items on which discounts will be applied',
        tooltip: 'These items will need to be present in the cart for the discount to be applied'
      }
    ],
    thresholdUIConfig: [
      {
        title: 'Cart conditions',
        thresholdRadioGroup: { radioItemsUIConfig: thresholdRadioGroupOptions },
        valueType: 'CALLOUT_CARD'
      },
      {
        isHidden: true,
        valueType: 'BUNDLE'
      },
      {
        title: 'Cart conditions',
        thresholdRadioGroup: { radioItemsUIConfig: thresholdRadioGroupOptions },
        valueType: 'BXGY'
      },
      {
        title: 'Cart conditions',
        thresholdRadioGroup: { radioItemsUIConfig: thresholdRadioGroupOptions },
        valueType: 'AMOUNT'
      },
      {
        title: 'Cart conditions',
        thresholdRadioGroup: { radioItemsUIConfig: thresholdRadioGroupOptions },
        valueType: 'PREPAID'
      }
    ],
    prerequisitesUIConfig: [
      {
        isHidden: true,
        valueType: 'NONE'
      },
      {
        title: 'Customer buys',
        valueType: 'AMOUNT',
        minThresholdInput: {
          label: 'Minimum purchase amount',
          startIconComponent: '₹',
          type: 'number'
        },
        maxThresholdCheckbox: {
          label: 'Set maximum purchase amount'
        },
        maxThresholdInput: [
          {
            isHidden: true,
            valueType: false
          },
          {
            label: 'Maximum purchase amount',
            startIconComponent: '₹',
            type: 'number',
            valueType: true
          }
        ],
        prerequisteRadioGroup: { radioItemsUIConfig: prerequisiteRadioGroupOptions },
        prerequisiteItemsUIConfig: catalogueSelectionConfigs
      },
      {
        title: 'Customer buys',
        valueType: 'QUANTITY',
        minThresholdInput: {
          label: 'Minimum quantity',
          type: 'number'
        },
        maxThresholdCheckbox: {
          label: 'Set maximum quantity'
        },
        maxThresholdInput: [
          {
            isHidden: true,
            valueType: false
          },
          {
            label: 'Maximum quantity',
            type: 'number',
            valueType: true
          }
        ],
        prerequisteRadioGroup: { radioItemsUIConfig: prerequisiteRadioGroupOptions },
        prerequisiteItemsUIConfig: catalogueSelectionConfigs
      }
    ]
  },
  eligibilityUIConfig: {
    customerEligibilityUIConfig: {
      title: 'Customer eligibility',
      eligibilityRadioGroup: { radioItemsUIConfig: eligibilityRadioGroupOptions },
      uploadCSVUIConfig: uploadCSVUIConfigs,
      minOrderCountInput: minOrderCountInputConfigs,
      maxOrderCountCheckbox: maxOrderCountCheckboxConfigs,
      maxOrderCountInput: maxOrderCountInputConfigs
    }
  },
  usageLimitsUIConfig: {
    discountUsesUIConfig: [
      {
        isHidden: true,
        valueType: 'NOT_APPLICABLE'
      },
      {
        isHidden: true,
        valueType: 'POST_ORDER_PLACEMENT'
      },
      {
        valueType: 'PRE_PAYMENT_VALIDATION',
        title: 'Maximum discount uses',
        totalUsesCheckbox: [
          {
            label: 'Limit number of times this discount can be used in total',
            valueType: 'SINGLE'
          }
        ],
        totalUsesInput: [
          {
            isHidden: true,
            valueType: false
          },
          {
            valueType: true,
            type: 'number' as any
          }
        ],
        usesPerCustomerCheckbox: [
          {
            label: 'Limit number of times this discount can be used per customer',
            valueType: 'SINGLE'
          }
        ],
        usesPerCustomerInput: [
          {
            isHidden: true,
            valueType: false
          },
          {
            valueType: true,
            type: 'number' as any
          }
        ]
      },
      {
        valueType: 'CART_DISCOVERY',
        title: 'Maximum discount uses',
        totalUsesCheckbox: [
          {
            label: 'Limit number of times codes from this set can be used in total',
            valueType: 'BULK'
          },
          {
            label: 'Limit number of times this discount can be used in total',
            valueType: 'SINGLE'
          }
        ],
        totalUsesInput: [
          {
            isHidden: true,
            valueType: false
          },
          {
            valueType: true,
            type: 'number' as any
          }
        ],
        usesPerCustomerCheckbox: [
          {
            label: 'Limit number of times this discount can be used per customer',
            isDisabled: true,
            valueType: 'BULK',
            defaultValue: true
          },
          {
            label: 'Limit number of times this discount can be used per customer',
            valueType: 'SINGLE'
          }
        ],
        usesPerCustomerInput: [
          {
            isHidden: true,
            valueType: false
          },
          {
            valueType: true,
            type: 'number' as any
          }
        ]
      }
    ],
    stackabilityUIConfig: [
      {
        isHidden: true,
        valueType: 'NOT_APPLICABLE'
      },
      {
        isHidden: true,
        valueType: 'POST_ORDER_PLACEMENT'
      },
      {
        valueType: 'PRE_PAYMENT_VALIDATION',
        isHidden: true
      },
      {
        valueType: 'CART_DISCOVERY',
        title: 'Combination',
        stackabilityRadioGroup: {
          isHidden: false,
          radioItemsUIConfig: customStackabilityRadioGroupOptions
        },
        infoText:
          'discounts at a time and discounts will be calculated in order of application. Automatic discounts are set to combine by default.',
        combinableDiscountsLink: {
          type: 'link' as any
        }
      }
    ],
    activePeriodUIConfig: {
      title: 'Active period',
      startDateInput: [
        {
          isHidden: true,
          valueType: 'SHORT'
        },
        {
          label: 'Start date',
          type: 'date',
          valueType: 'LONG',
          startIconComponent: 'Calendar'
        }
      ],
      startTimeInput: [
        {
          isHidden: true,
          valueType: 'SHORT'
        },
        {
          label: 'Start time (IST)',
          type: 'time',
          valueType: 'LONG',
          startIconComponent: 'Clock'
        }
      ],
      endDateCheckbox: [
        {
          isHidden: true,
          valueType: 'SHORT'
        },
        {
          label: 'Set end date',
          valueType: 'LONG'
        }
      ],
      endDateInput: [
        {
          isHidden: true,
          valueType: false
        },
        {
          label: 'End date',
          type: 'date',
          valueType: true,
          startIconComponent: 'Calendar'
        }
      ],
      endTimeInput: [
        {
          isHidden: true,
          valueType: false
        },
        {
          label: 'End time (IST)',
          type: 'time',
          valueType: true,
          startIconComponent: 'Clock'
        }
      ],
      activePeriodInput: [
        {
          isHidden: true,
          valueType: 'LONG'
        },
        {
          label: 'Offer timeout',
          type: 'period',
          valueType: 'SHORT',
          endIconComponent: 'minutes'
        }
      ]
    }
  },
  previewUIConfig: {
    visibilityUIConfig: {
      title: 'Coupon visibility',
      visibilityCheckbox: {
        label: 'Show discount coupon to the customer'
      }
    },
    previewUIConfig: {
      title: 'Edit descriptions'
    }
  }
}

/*****************************************************************************************/
//                                   For State Management
/*****************************************************************************************/

export const defaultDiscountState: DiscountFormStateType = {
  discountId: '',
  setup: {
    applicationMethodConfig: {
      valueType: 'MANUAL',
      value: ''
    },
    applicationConfig: {
      valueType: 'AMOUNT',
      discountDescription: {
        discountTitle: '',
        discountHeader: '',
        discountDescription: '',
        discountLongDescription: ''
      },
      entitlementsConfig: {
        valueType: 'ALL',
        entitledItemsConfig: {},
        setQuantityCheckboxConfig: { valueType: false },
        quantityDistributorConfig: [
          {
            quantity: 1,
            itemsConfig: {}
          }
        ]
      },
      offerValueConfig: {
        valueType: 'PERCENTAGE',
        value: '',
        cappedValue: '',
        quantity: '',
        capCheckboxConfig: { valueType: false },
        paymentModeCoverageType: 'ALL',
        isCodAccepted: false,
        paymentModeConcessions: [],
        isCustomHeader: 'DEFAULT'
      },
      discountGenerationConfig: {
        valueType: 'SINGLE',
        bulkGenerationConfig: {
          valueType: 'RANDOM_CODES',
          codeCount: '',
          codeLength: '',
          prefix: '',
          suffix: '',
          allowedCharacters: 'LETTERS_AND_NUMBERS'
        }
      },
      maxUsesPerOrderCheckboxConfig: {
        valueType: false
      },
      maxUsesPerOrder: ''
    }
  },
  conditions: {
    thresholdConfig: {
      valueType: 'NONE',
      maxValue: '',
      minValue: '',
      maxCheckboxConfig: { valueType: false }
    },
    prerequisitesConfig: {
      valueType: 'ALL',
      setQuantityCheckboxConfig: { valueType: false },
      quantityDistributorConfig: [
        {
          quantity: 1,
          itemsConfig: {}
        }
      ]
    }
  },
  customerEligibility: {
    valueType: 'ALL',
    minOrderCount: '',
    maxOrderCount: '',
    maxOrderCountCheckboxConfig: { valueType: false },
    segmentIds: []
  },
  usageLimits: {
    discountUsesConfig: {
      maxUsesCheckboxConfig: { valueType: false },
      maxUses: '',
      // oncePerCustomer: false,
      maxUsesPerCustomerCheckboxConfig: { valueType: false },
      maxUsesPerCustomer: ''
    },
    stackabilityType: {
      valueType: 'ALL'
    },
    activePeriodConfig: {
      startDate: new Date(),
      endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      endDataCheckboxConfig: { valueType: false },
      activePeriod: 5
    }
  },
  preview: {
    isDiscoverable: false,
    descriptionConfig: {
      descriptionTitle: '',
      description: '',
      longDescription: []
    }
  },
  isActive: true,
  tags: [],
  channels: []
}

export const defaultCalloutCardState: DiscountFormStateType = {
  discountId: '',
  setup: {
    applicationConfig: {
      valueType: 'CALLOUT_CARD',
      discountDescription: {
        discountTitle: '',
        discountHeader: '',
        discountDescription: '',
        discountLongDescription: ''
      },
      discountGenerationConfig: {
        valueType: 'SINGLE'
      },
      entitlementsConfig: {
        valueType: 'ALL'
      }
    }
  },
  conditions: {
    thresholdConfig: {
      valueType: 'NONE',
      maxValue: '',
      minValue: '',
      maxCheckboxConfig: { valueType: false }
    },
    prerequisitesConfig: {
      valueType: 'ALL',
      setQuantityCheckboxConfig: { valueType: false },
      quantityDistributorConfig: [
        {
          quantity: 1,
          itemsConfig: {}
        }
      ]
    }
  },
  customerEligibility: {
    valueType: 'ALL',
    minOrderCount: '',
    maxOrderCount: '',
    maxOrderCountCheckboxConfig: { valueType: false },
    segmentIds: []
  },
  usageLimits: {
    discountUsesConfig: {
      maxUsesCheckboxConfig: { valueType: false },
      maxUses: '',
      // oncePerCustomer: false,
      maxUsesPerCustomerCheckboxConfig: { valueType: false },
      maxUsesPerCustomer: ''
    },
    stackabilityType: {
      valueType: 'ALL'
    },
    activePeriodConfig: {
      startDate: new Date(),
      endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      endDataCheckboxConfig: { valueType: false },
      activePeriod: 5
    }
  },
  preview: {
    isDiscoverable: true
  },
  isActive: true,
  tags: [],
  channels: []
}

/*****************************************************************************************/
//                                   For Discount Flows
/*****************************************************************************************/

export const continueButton = {
  text: 'Continue',
  iconComponent: 'ArrowRight',
  type: 'contained' as any
}
export const saveButton = {
  text: 'Save',
  iconComponent: 'Save',
  type: 'outlined' as any
}
export const cancelButton = {
  text: 'Cancel',
  type: 'contained' as any
}
export const createButton = {
  text: 'Create discount',
  type: 'contained' as any
}
const saveAndContinueButton = {
  text: 'Save',
  iconComponent: 'ArrowRight',
  type: 'contained' as any
}

export const createDiscountJourneyLineConfig: JourneyLineStepConfigType[] = [
  {
    label: 'Setup',
    step: 'setup',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Cart conditions',
    step: 'condition',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Customer eligibility',
    step: 'eligibility',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Usage limits',
    step: 'limits',
    saveButton: saveButton,
    continueButton: continueButton,
    createButton: createButton
  },
  {
    label: 'Preview',
    step: 'preview',
    createButton: createButton
  }
]
export const createDiscountSetJourneyLineConfig: JourneyLineStepConfigType[] = [
  {
    label: 'Setup',
    step: 'setup',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Cart conditions',
    step: 'condition',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Customer eligibility',
    step: 'eligibility',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Usage limits',
    step: 'limits',
    createButton: createButton
  }
]
export const editDiscountJourneyLineConfig: JourneyLineStepConfigType[] = [
  {
    label: 'Setup',
    step: 'setup',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Cart conditions',
    step: 'condition',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Customer eligibility',
    step: 'eligibility',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Usage limits',
    step: 'limits',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Preview',
    step: 'preview',
    saveButton: saveButton
  }
]
export const editDiscountSetJourneyLineConfig: JourneyLineStepConfigType[] = [
  {
    label: 'Setup',
    step: 'setup',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Cart conditions',
    step: 'condition',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Customer eligibility',
    step: 'eligibility',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Usage limits',
    step: 'limits',
    saveButton: saveButton
  }
]
export const createC2PDiscountJourneyLineConfig: JourneyLineStepConfigType[] = [
  {
    label: 'Set discount type',
    step: 'setup',
    cancelButton: cancelButton,
    createButton: createButton
  },
  {
    label: 'Set discount position',
    step: 'C2P'
  }
]
export const editC2PDiscountJourneyLineConfig: JourneyLineStepConfigType[] = [
  {
    label: 'Set discount type',
    step: 'setup',
    saveAndContinueButton: saveAndContinueButton,
    cancelButton: cancelButton
  },
  {
    label: 'Set discount position',
    step: 'preview'
  }
]

export const createCalloutCardJourneyLineConfig: JourneyLineStepConfigType[] = [
  {
    label: 'Title & description',
    step: 'setup',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Cart conditions',
    step: 'condition',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Customer eligibility',
    step: 'eligibility',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Usage limits',
    step: 'limits',
    createButton: createButton
  }
]

export const editCalloutCardJourneyLineConfig: JourneyLineStepConfigType[] = [
  {
    label: 'Setup',
    step: 'setup',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Cart conditions',
    step: 'condition',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Customer eligibility',
    step: 'eligibility',
    saveButton: saveButton,
    continueButton: continueButton
  },
  {
    label: 'Usage limits',
    step: 'limits',
    saveButton: saveButton
  }
]

export const discountFlowConfig: DiscountFlowConfigType[] = [
  {
    type: 'create-discount',
    pageHeader: 'Create discount',
    generationType: 'SINGLE',
    defaultDiscountState: defaultDiscountState,
    journeyLineConfig: createDiscountJourneyLineConfig,
    periodLength: 'LONG',
    applicationStage: 'CART_DISCOVERY',
    goBackUrl: '/discounts'
  },
  {
    type: 'edit-discount',
    pageHeader: 'Edit discount',
    isEditMode: true,
    generationType: 'SINGLE',
    journeyLineConfig: editDiscountJourneyLineConfig,
    periodLength: 'LONG',
    applicationStage: 'CART_DISCOVERY',
    goBackUrl: '/discounts'
  },
  {
    type: 'create-discount-set',
    pageHeader: 'Create discount set',
    generationType: 'BULK',
    defaultDiscountState: defaultDiscountState,
    journeyLineConfig: createDiscountSetJourneyLineConfig,
    periodLength: 'LONG',
    applicationStage: 'CART_DISCOVERY',
    goBackUrl: '/discounts'
  },
  {
    type: 'edit-discount-set',
    pageHeader: 'Edit discount set',
    generationType: 'BULK',
    isEditMode: true,
    journeyLineConfig: editDiscountSetJourneyLineConfig,
    periodLength: 'LONG',
    applicationStage: 'CART_DISCOVERY',
    goBackUrl: '/discounts'
  },
  {
    type: 'create-c2p-discount',
    pageHeader: 'COD to prepaid conversion discount',
    generationType: 'SINGLE',
    defaultDiscountState: defaultDiscountState,
    journeyLineConfig: createC2PDiscountJourneyLineConfig,
    periodLength: 'SHORT',
    applicationStage: 'POST_ORDER_PLACEMENT',
    goBackUrl: '/rto-suite/create-c2p-link'
  },
  {
    type: 'edit-c2p-discount',
    generationType: 'SINGLE',
    pageHeader: 'COD to prepaid conversion discount',
    isEditMode: true,
    journeyLineConfig: editC2PDiscountJourneyLineConfig,
    periodLength: 'SHORT',
    applicationStage: 'POST_ORDER_PLACEMENT',
    goBackUrl: '/rto-suite/edit-c2p-link'
  },
  {
    type: 'create-payment-offer',
    generationType: 'SINGLE',
    pageHeader: 'Create payment offer',
    defaultDiscountState: defaultDiscountState,
    journeyLineConfig: createDiscountJourneyLineConfig,
    periodLength: 'LONG',
    applicationStage: 'PRE_PAYMENT_VALIDATION',
    goBackUrl: '/payment-offers'
  },
  {
    type: 'edit-payment-offer',
    generationType: 'SINGLE',
    pageHeader: 'Edit payment offer',
    isEditMode: true,
    journeyLineConfig: editDiscountJourneyLineConfig,
    periodLength: 'LONG',
    applicationStage: 'PRE_PAYMENT_VALIDATION',
    goBackUrl: '/payment-offers'
  },
  {
    type: 'create-callout-card',
    generationType: 'SINGLE',
    pageHeader: 'Create callout card',
    defaultDiscountState: defaultCalloutCardState,
    journeyLineConfig: createCalloutCardJourneyLineConfig,
    periodLength: 'LONG',
    applicationStage: 'NOT_APPLICABLE',
    goBackUrl: '/discounts'
  },
  {
    type: 'edit-callout-card',
    generationType: 'SINGLE',
    pageHeader: 'Edit callout card',
    isEditMode: true,
    journeyLineConfig: editCalloutCardJourneyLineConfig,
    periodLength: 'LONG',
    applicationStage: 'NOT_APPLICABLE',
    goBackUrl: '/discounts'
  }
]

export const exportOptionData: ExportOptionConfigType[] = [
  {
    valueType: 'discounts',
    label:
      'The report will contain a discount-wise summary for the orders and GMV processed in the selected date range.'
  }
]

export const importOptionData: ExportOptionConfigType[] = [
  {
    valueType: 'discountImport',
    label: 'To show discounts created outside Shopflo as a coupon card on Shopflo Checkout, import their codes here.',
    secondaryLabel:
      'Customers can still manually enter codes to apply discounts that haven’t been imported on the Shopflo dashboard.'
  }
]

export const discountCreationModes: DiscountCreationModeConfigType[] = [
  {
    value: 'DISCOUNTS',
    label: 'Create single discount',
    iconComp: 'Tag',
    description: 'How to create discounts on Shopflo?',
    link: '/discounts/create-discount',
    id: 'create-single-discount'
  },
  {
    value: 'SETS',
    label: 'Create bulk discount sets',
    iconComp: 'Layers',
    description: 'How to create discount sets on Shopflo?',
    link: '/discounts/create-discount-set',
    permissionId: permissionType.BULK_CREATE_DISCOUNTS,
    id: 'create-bulk-discount-sets'
  },
  {
    value: 'IMPORTS',
    label: 'Import discount from Shopify',
    iconComp: 'Copy',
    description: 'How to import discounts to Shopflo?',
    link: 'javascript:setIsDiscountImportOpen(true);',
    id: 'import-discount-from-shopify'
  },
  {
    value: 'CALLOUT_CARDS',
    label: 'Create a Callout card',
    iconComp: 'CreditCard',
    description: 'How to create callout cards on Shopflo?',
    link: '/discounts/create-callout-card',
    permissionId: permissionType.BULK_CREATE_DISCOUNTS,
    id: 'create-callout-card'
  }
]

// Error states

export const defaultErrorState = {
  status: false,
  errorCode: ''
}

export const defaultQuantityDistributorErrorState = {
  status: false,
  errorCode: '',
  indexes: []
}

export const initialFormErrorState: DiscountFormErrorState = {
  identifier: {
    status: false,
    errorCode: ''
  },
  discountTitle: {
    status: false,
    errorCode: ''
  },
  discountHeader: {
    status: false,
    errorCode: ''
  },
  discountDescription: {
    status: false,
    errorCode: ''
  },
  codeCount: {
    status: false,
    errorCode: ''
  },
  codeLength: {
    status: false,
    errorCode: ''
  },
  codeCSV: {
    status: false,
    errorCode: ''
  },
  customerCSV: {
    status: false,
    errorCode: ''
  },
  offerValue: {
    status: false,
    errorCode: ''
  },
  offerLimit: {
    status: false,
    errorCode: ''
  },
  entitledQuantity: {
    status: false,
    errorCode: ''
  },
  minRequirement: {
    status: false,
    errorCode: ''
  },
  maxRequirement: {
    status: false,
    errorCode: ''
  },
  maxOrderCount: {
    status: false,
    errorCode: ''
  },
  minOrderCount: {
    status: false,
    errorCode: ''
  },
  maxUses: {
    status: false,
    errorCode: ''
  },
  prerequisteProducts: {
    status: false,
    errorCode: ''
  },
  entitledProducts: {
    status: false,
    errorCode: ''
  },
  startDate: {
    status: false,
    errorCode: ''
  },
  endDate: {
    status: false,
    errorCode: ''
  },
  activePeriod: {
    status: false,
    errorCode: ''
  },
  paymentModeChecklist: {
    status: false,
    errorCode: ''
  },
  quantityDistributor: {
    status: false,
    errorCode: '',
    indexes: []
  },
  quantityDistributorProduct: {
    status: false,
    errorCode: '',
    indexes: []
  }
}

export const stackLengthConfigurationOptions = [
  {
    label: 'Don’t allow',
    valueType: false
  },
  {
    label: 'Allow',
    valueType: true
  }
]

export const discountsTabList: SwitchableTabType[] = [
  {
    isActive: true,
    label: 'Codes',
    name: 'CODES',
    childComponent: 'DiscountTable'
  },
  {
    isActive: false,
    label: 'Sets',
    name: 'SETS',
    childComponent: 'DiscountSetsLookup',
    permissionId: permissionType.BULK_CREATE_DISCOUNTS
  },
  {
    isActive: false,
    label: 'Callout cards',
    name: 'CALLOUT_CARDS',
    childComponent: 'CalloutCardsLookup'
  }
]

export const prepaidPaymentMode = [
  'WALLET', //Wallet
  'UPI_INTENT', //UPI
  'UPI_COLLECT', //UPI
  'UPI', //UPI
  'SNPM', //Snapmint
  'SAVED_MODES', //Saved mode
  'PAY_LATER', //Pay later
  'NETBANKING', //Netbanking
  'FREE', //Online payment
  'EMI', //EMI
  'CASHFREE', //Cashfree
  'CARD', //Card
  'PL', //Online payment
  'GC', //Gift card
  'BHARATX' //BharatX
]
