import Cookies from 'js-cookie'
import { apiURI, constants } from './constants'

const API_VERSION = Cookies.get(constants.FLO_SHOPFLO_VERSION) ?? 'stable'
export const getRequestParams = (isMultiPartFormData = false, baseURI = apiURI.FLO_CHECKOUT) => {
  const headers = {
    ...(isMultiPartFormData ? { 'Content-Type': 'multipart/form-data' } : {}),
    Authorization: Cookies.get(constants.AUTH_COOKIE_CLIENT) ?? '',
    'X-SHOPFLO-VERSION': API_VERSION
  }
  return { headers }
}

export const getResponseParams = (
  baseURI = apiURI.FLO_CHECKOUT,
  response: any,
  isError = false,
  contentType?: string
) => {
  let data = {}
  let headers = {}
  let error = {}
  switch (baseURI) {
    case apiURI.FLO_CHECKOUT: {
      data = contentType === 'text/csv' ? response : response?.data?.response
      error = isError ? response?.data?.error : null
      headers = response?.headers
      break
    }
    case apiURI.FLO_KRATOS_CHECKOUT: {
      data = contentType === 'text/csv' ? response : response?.data?.data
      error = isError ? response?.data?.error?.message : null
      headers = response?.headers
      break
    }
    case apiURI.PUBLIC_FLO_CHECKOUT: {
      data = response?.data?.response
      error = isError ? response?.data?.error : null
      headers = response?.headers
      break
    }
    case apiURI.CHECKOUT_NODE: {
      data = response?.data?.data
      error = isError ? response?.data?.errorMessage ?? 'Something went wrong!' : null
      headers = response?.headers
      break
    }
    case apiURI.DISCOUNT_ENGINE: {
      data = response?.data
      error = isError ? response?.data?.error?.message ?? response?.data?.error : null
      headers = response?.headers
      break
    }
    case apiURI.CATALOGUE_ENGINE: {
      data = response?.data
      error = isError ? response?.data?.error?.message ?? response?.data?.error : null
      headers = response?.headers
      break
    }
    case apiURI.PINCODE_ENGINE: {
      data = response?.data
      error = isError ? response?.data?.error?.message ?? response?.data?.error : null
      headers = response?.headers
      break
    }
    case apiURI.AUTH_ENGINE: {
      data = response?.data
      error = isError ? response?.data?.error?.message ?? response?.data?.error : null
      headers = response?.headers
      break
    }
    case apiURI.REWARDS_ENGINE: {
      data = response?.data
      error = isError ? response?.data?.error?.message ?? response?.data?.error : null
      headers = response?.headers
      break
    }
    case apiURI.SHIPPING_ENGINE: {
      data = response?.data?.data
      error = isError ? response?.data?.error?.message ?? response?.data?.error : null
      headers = response?.headers
      break
    }
    case apiURI.SHOPIFY_CONNECTOR: {
      data = response?.data?.data
      error = isError ? response?.data?.error?.message : null
      headers = response?.headers
      break
    }
    case apiURI.FMS_ENGINE: {
      data = response?.data?.response
      error = isError ? response?.data?.error : null
      headers = response?.headers
      break
    }
    case apiURI.SETTLEMENT_ENGINE: {
      data = response?.data
      error = isError ? response?.data?.error?.message : null
      headers = response?.headers
      break
    }
    case apiURI.SETTLEMENT_ENGINE_V2: {
      data = response?.data
      error = isError ? response?.data?.error : null
      headers = response?.headers
      break
    }
    case apiURI.SEGMENTS_ENGINE: {
      data = response?.data
      error = isError ? response?.data?.error : null
      headers = response?.headers
      break
    }
  }
  return { data, error, headers }
}
